// Reusable brushstroke class that resizes according to column and page size
.brushstroke,
.brushstroke-alt {
	display: block;
	background-image: url(../images/brushstroke.png);
	background-repeat: no-repeat;
	background-position-y: center;
	background-size: 780px; // adjusted for breakpoints
	color: #fff;
	font-size: 3rem;
	font-weight: bold;
	height: 8rem;
	margin: 0;
	position: relative;
	width: 100%;
	span {
		position: absolute;
		text-align: center;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
	}
}
.brushstroke {
	background-position-x: 95%;
	font-size: 3rem;
	position: absolute;
	top: 50%;
	transform: translateY(-55%);
	span {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}

	@media (min-width: 768px) {
		font-size: 4rem;
	}
}
.brushstroke-alt {
	background-color: inherit;
	background-image: url(../images/brushstroke-2.png);
	background-size: 800px;
	background-position-x: 100%;
	font-size: 1.75rem;
	height: 9rem;

	@media (min-width: 768px) {
		font-size: 1.4rem;
	}

	@media (min-width: 992px) {
		font-size: 1.75rem;
	}
}
@media (min-width: 768px) {
	.col-sm-4,
	.col-sm-6 {
		> .brushstroke {
			background-size: 800px;
		}
	}
}
@media (min-width: 992px) {
	.col-md-4 {
		> .brushstroke {
			background-size: 600px;
		}
	}
	.col-md-6 {
		> .brushstroke {
			background-size: 900px;
		}
	}
}
