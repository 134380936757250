//** Layout Widths
$site-max-width: 1280px;
$site-width-padding: 20px;

//** Breakpoints
$screen-xl: 1440px;
$screen-l: 1045px;
$screen-m: 768px;
$screen-s: 639px;
$screen-xs: 479px;


$font-primary: "Lexend", sans-serif;

$font-light: 300;
$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-bold: 700;
$font-extrabold: 800;

//** Colors
$brand-blue: #004FAF;
$brand-green: #75AA50;
$brand-dark-green: #009846;
$brand-dark-green-hover: #007a38;
$brand-yellow: #ffbf00;
$brand-grey: #E6E6E6;
$black: #1F2226;
$white: #fff;
$dark-grey: #333;
$bg-light-gray: #f6f6f6;

$text-color-disabled: #7d7d7d;
$border-color-disabled: #c2c2c2;
$bg-color-disabled: #f0f0f0;
$bg-light-gray: #f6f6f6;

$border-line-grey: #d5d5d5;
$border-line-black: #000;

$btn-border-radius: 3px;

$side-padding-m: 20px;
$side-padding-t: 20px;

$tablet-breakpoint: 1090px;

$header-height-desktop: 155px;
$header-height-mobile: 86px;
$header-height-tablet: 211px;
