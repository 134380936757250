.ampickup-store-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
}
.ampickup-store-container.-cart {
    padding: 0 5px;
}
.ampickup-store-container .ampickup-field {
    width: 100%;
}
.ampickup-store-container .ampickup-field:not(:last-of-type) {
    margin-bottom: 10px;
}
.ampickup-store-container .ampickup-field select,
.ampickup-store-container .ampickup-field input {
    padding: 5px 10px;
    height: inherit;
}
.ampickup-store-container .ampickup-field.-store .ampickup-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.ampickup-store-container .ampickup-field.-date .control {
    display: flex;
    flex-wrap: wrap;
}
.ampickup-store-container .ampickup-field.-date input {
    flex: 1 1 80%;
}
.ampickup-store-container .ampickup-field.-date .ui-datepicker-trigger {
    flex: 1 1 20%;
    max-width: 50px;
}
.ampickup-store-container .ampickup-separator {
    align-self: stretch;
    margin: 15px 0;
    width: inherit;
    border-top: 1px solid #c7c7c7;
}
.ampickup-store-container .ampickup-choosemap {
    display: inline-block;
    margin: 0 auto 0 0;
    padding: 5px 15px;
    border-radius: 4px;
    background: #1979c3;
    color: #fff;
    white-space: nowrap;
    cursor: pointer;
}
.ampickup-store-container .ampickup-choosemap:hover {
    background: #135896;
}
.ampickup-store-container .label {
    display: inline-block;
    margin-bottom: 8px;
}
.ampickup-details-container {
    flex: 1 1 100%;
    margin-bottom: 15px;
}
.ampickup-details-container .ampickup-title {
    margin: 0 0 15px;
}
.ampickup-details-container div:empty,
.ampickup-details-container div:empty + br {
    display: none;
}
.amcheckout-step-container .amcheckout-shipping-address .ampickup-details-container.shipping-address-details {
    width: 100%;
}
.ampickup-map-popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;

    .ampickup-locations {
        > h1,
        > h2,
        > h3,
        > label {
            margin-bottom: 15px;
            font-size: 18px;
            font-weight: 600;
        }
    }

    .amlocator-main-container {
        .amlocator-map-container {
            @media (min-width: 768px) {
                height: calc(100vh - 200px);
            }
        }
    }
}
.ampickup-map-popup .ampickup-overlay {
    position: absolute;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
}
.ampickup-map-popup .ampickup-title {
    display: block;
    margin-bottom: 15px;
    font-size: 22px;
}
.ampickup-map-popup .ampickup-content {
    position: relative;
    z-index: 2;
    overflow: auto;
    box-sizing: border-box;
    padding: 25px;
    max-height: 95vh;
    width: 90vw;
    border-radius: 4px;
    background: #fff;
}
.ampickup-map-popup .ampickup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    opacity: .3;
    cursor: pointer;
}
.ampickup-map-popup .ampickup-close:hover {
    opacity: 1;
}
.ampickup-map-popup .ampickup-close:before,
.ampickup-map-popup .ampickup-close:after {
    position: absolute;
    left: 11px;
    width: 2px;
    height: 27px;
    background-color: #333;
    content: ' ';
}
.ampickup-map-popup .ampickup-close:before {
    transform: rotate(45deg);
}
.ampickup-map-popup .ampickup-close:after {
    transform: rotate(-45deg);
}
.ampickup-map-popup .amlocator-block.-map {
    box-sizing: border-box;
}
.ampickup-nostores-message {
    margin-top: 15px;
}
.ampickup-nostores-message.-background {
    margin-bottom: 15px;
    padding: 10px;
    background: #f6f6f6;
}
.ampickup-curbside-banner-img {
    width: 100%;
}
.ampickup-options-container {
    box-sizing: border-box;
    margin: 5px 0 20px;
    width: 100%;
    word-wrap: break-word;
    word-break: break-word;
}
.ampickup-options-container .ampickup-comment {
    display: block;
}
.ampickup-options-container .ampickup-comment label {
    display: none;
}
.checkout-index-index .ampickup-options-container {
    margin-bottom: 40px;
}
.ampickup-conditions-container.block {
    margin-top: 5px;
    margin-bottom: 10px !important;
}
.ampickup-conditions-container.block > .title {
    padding: 5px 0;
    border-top: none;
}
.ampickup-conditions-container.block > .title:after {
    top: 7px;
}
.ampickup-conditions-container.block > .content {
    padding: 10px 0 0;
    word-wrap: break-word;
    word-break: break-word;
}
.ampickup-curbside-label {
    display: flex;
    align-items: center;
    margin-top: 5px;
}
.ampickup-curbside-label:before {
    display: block;
    flex-shrink: 0;
    margin-right: 5px;
    width: 18px;
    height: 18px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTkgLjVjLTQuOTYgMC05IDQuMDQtOSA5czQuMDQgOSA5IDkgOS00LjA0IDktOS00LjA0LTktOS05em00LjE4NiA3LjM2N2wtNS4wMjMgNS4wMjRhLjgyNy44MjcgMCAwMS0uNTg2LjI1LjgyNy44MjcgMCAwMS0uNTg2LS4yNWwtMi41OTYtMi41NTRhLjgzNC44MzQgMCAwMTAtMS4xOTMuODM0LjgzNCAwIDAxMS4xOTMgMGwxLjk2OCAxLjk2OCA0LjQzNy00LjQzOGEuODM0LjgzNCAwIDAxMS4xOTMgMCAuODczLjg3MyAwIDAxMCAxLjE5M3oiIGZpbGw9IiM4MkI2ODciLz48L3N2Zz4=) no-repeat center;
    content: '';
}
.amlocator-store-information .ampickup-curbside-label,
.amlocator-info-popup .ampickup-curbside-label {
    margin-bottom: 5px;
}
.amlocator-location-info .ampickup-curbside-label {
    margin-bottom: 10px;
}
.ampickup-map-popup .amlocator-button.-pickup {
    float: right;
    margin-top: 10px;
    min-height: inherit;
}
.ampickup-map-popup .amlocator-button.-pickup:hover {
    background: #2b93e4;
}
.ampickup-map-popup .amlocator-search-container .amlocator-title {
    display: block;
}
.ampickup-map-popup .amlocator-search-container .amlocator-select.-measurement {
    margin-bottom: 5px;
}
.ampickup-map-popup .amlocator-search-radius .amlocator-radio + .amlocator-label {
    padding: 5px 10px;
}
.ampickup-map-popup .amlocator-search-radius .amlocator-radio + .amlocator-label:hover {
    background: #dfdfdf;
}
.ampickup-map-popup .amlocator-main-container .amlocator-block.-separator {
    border-top: 1px solid #e7e7e7;
}
.ampickup-map-popup .amlocator-attribute-wrapper .amlocator-label {
    cursor: pointer;
}
.ampickup-map-popup .amlocator-filters-container .amlocator-clear {
    border: none;
    background: none;
    box-shadow: none;
    font-weight: normal;
    font-size: 12px;
}
.ampickup-map-popup .amlocator-store-desc.-active .amlocator-button.-pickup {
    margin-top: 10px;
    background: #f6f6f6;
    color: #1979c3;
}
.ampickup-map-popup .amlocator-store-desc.-active .amlocator-button.-pickup:hover {
    background: #dddddd;
}
.ampickup-map-popup .amlocator-store-information {
    flex: auto;
}
.ampickup-details-container .amlocator-location-info {
    padding: 0;
}
.ampickup-details-container .amlocator-link {
    display: flex;
    color: inherit;
    word-wrap: break-word;
    word-break: break-word;
}
.ampickup-details-container .amlocator-link:not(:last-of-type) {
    margin-bottom: 7px;
}
.ampickup-details-container .amlocator-link .amlocator-icon {
    min-width: 20px;
}
.pac-container.pac-logo {
    z-index: 999999;
}
.paypal-review .ampickup-store-container.fieldset {
    display: flex;
}
.paypal-review .fieldset > .ampickup-field.field > .label {
    display: inline-block;
}
.paypal-review a.-disabled {
    opacity: .5;
    cursor: not-allowed;
}
@media all and (min-width: 768px), print {
    .amlocator-main-container .amlocator-search-container {
        width: 33%;
    }
    .amlocator-main-container .amlocator-search-container .amlocator-block {
        margin-bottom: 0;
        text-align: center;
        margin-bottom: 5px;
    }
    .amlocator-main-container .amlocator-search-container .amlocator-button.-nearby {
        color: #1979c3;
        background: #fff;
        margin-top: 10px;
        width: 100%;
        margin-left: auto;
    }
    .amlocator-main-container .amlocator-search-container .amlocator-button.-nearby:hover,
    .amlocator-main-container .amlocator-search-container .amlocator-button.-nearby:focus {
        color: #fff;
        background: #1979c3;
    }
    .amlocator-main-container .amlocator-search-container .amlocator-block.-separator {
        display: none;
    }
    .amlocator-main-container .amlocator-map-container {
        height: 100vh;
        max-height: 800px;
    }
    .amlocator-main-container .amlocator-map-container .amlocator-block.-filter {
        width: 33%;
    }
    .amlocator-main-container .amlocator-map-container .amlocator-block.-map {
        width: 67%;
        height: 80vh;
        max-height: 800px;
        padding-left: 15px;
    }
    .amlocator-main-container .amlocator-map-container .amlocator-block.-storelist {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        order: inherit;
        width: 33%;
    }
    .amlocator-main-container .amlocator-map-container .amlocator-map {
        height: 100%;
    }
    .amlocator-main-container .amlocator-map-container .amlocator-map button {
        top: 0 !important;
        right: 0 !important;
    }
    .amlocator-main-container .amlocator-store-list .amlocator-wrapper {
        max-height: inherit;
    }
    .amlocator-main-container .amlocator-filters-container .amlocator-content {
        display: block;
    }
    .amlocator-main-container .amlocator-filters-container .amlocator-hidden-filter {
        display: none;
    }
}
@media all and (min-width: 1440px), print {
    .amlocator-schedule-table .amlocator-cell.-time {
        padding-right: 7%;
        text-align: right;
    }
}
