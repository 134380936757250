#shop_by_product {
	.shop_by_product_box {
		display: block;
		height: 25rem;
		margin-bottom: 3rem;
		position: relative;
		transition: all 200ms;
		background-repeat: no-repeat;
		background-size: cover;
		&:hover {
			opacity: 0.8;
			.btn {
				background: #fff;
				color: #000;
			}
		}
		&.shop_by_product_interior {
			background-image: url(../images/shopbyproduct/shopbyproduct_interior.jpg);

		}
		&.shop_by_product_exterior {
			background-image: url(../images/shopbyproduct/shopbyproduct_exterior.jpg);
		}
		&.shop_by_product_primers {
			background-image: url(../images/shopbyproduct/shopbyproduct_primers-sealers.jpg);
		}
		&.shop_by_product_joinery {
			background-image: url(../images/shopbyproduct/shopbyproduct_joinery-trim.jpg);
		}
		&.shop_by_product_stains {
			background-image: url(../images/shopbyproduct/shopbyproduct_wood-stains.png);
		}
		&.shop_by_product_clears {
			background-image: url(../images/shopbyproduct/shopbyproduct_clear-finishes.png);
		}
		.brushstroke {
			font-size: 2.2rem;
		}
		button {
			background: #000;
			border: none;
			border-radius: 0;
			bottom: 1rem;
			color: #fff;
			left: 1rem;
			padding: 1rem 2rem;
			position: absolute;
		}
	}



}
