#html-body {

    &.catalog-product-view {

        // Buttons
        a#product_page_wishlist {
            @include btn();
            @include btn-grey();
            font-size: 15px;
            padding: 13px 17px;
            @media (max-width: 767px) {
                width: 100%;
                text-align: center;
            }
        }
        .action.datasheets {
            font-size: 1.6rem;
        }




        // product attributes and details
        .product.info.detailed {
            @media (min-width: 1045px) {
                width: 38% !important;
                clear: none !important;
                border-bottom: none !important;
                margin-top: 50px;
                float:right;
                margin-right: 30px;
                padding-right: 20px;
                border-bottom: 1px solid #d1d1d1;
            }


            // tabs
            .item.title {

                @media (max-width: 767px) {
                    a:after {
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        line-height: 1;
                        content: "\e607";
                        float: right;
                        font-family: "icons-blank-theme";
                        font-size: 3rem;
                        vertical-align: middle;
                        font-weight: bold;
                        speak: none;
                        text-align: center;
                    }
                    &.active a:after {
                        content: "\e618";
                    }
                }
            }



            .additional-attributes > tbody > tr > th,
            .additional-attributes > tbody > tr > td {
                @media (min-width: 768px) {
                    padding: 8px 10px 6px 12px !important;
                }
            }
            .additional-attributes > tbody > tr > th {
                @media (min-width: 768px) {
                    width: 50%;
                }
            }
            table {
                tr {
                    @media (min-width: 768px) {
                        padding: 6px;
                    }
                }
                tr:nth-child(even) {
                    @media (min-width: 768px) {
                        background-color: #f6f6f6;
                    }
                }
                th {
                    @media (min-width: 768px) {
                        max-width: 50%;
                    }
                }
            }



        }


    }

}

#product_addtocart_form {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    > dt, dd {
        flex: 100%;
    }

    > .product-options-wrapper {
        flex: 100%;
    }

    .select2-container--default .select2-selection--single,
    .super-attribute-select {
        height: 32px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        outline: none;

        &:active,
        &:focus {
            outline: none;
        }
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
        line-height: 32px;
    }

    > .product-options-bottom,
    > .box-tocart {
        width: calc(100% - 45px);
    }

    > .product-options-bottom {
        > .box-tocart {
            margin-right: 0;
            display: block;
        }
    }

    .box-tocart {
        .fieldset {
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;

            .field.qty {
                display: block;
                width: 60px;
                margin: 0;

                .input-text.qty{
                    width: 45px;
                    height: 45px;
                    border-radius: 4px;
                    -webkit-border-radius: 4px;
                    -moz-border-radius: 4px;
                    outline: none;

                    &:active,
                    &:focus {
                        outline: none;
                        box-shadow: 0 0 6px -2px $brand-green;
                    }
                }
            }

            .actions {
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
                -webkit-justify-content: center;
                -moz-justify-content: center;
                -ms-justify-content: center;
                justify-content: center;
                width: calc(100% - 60px);
                padding-top: 25px;

                .action.primary.tocart {
                    padding-top: 12px;
                    padding-bottom: 13px;
                    padding-left: 3px;
                    padding-right: 3px;
                    width: 100%;
                    border-radius: 4px;
                    -webkit-border-radius: 4px;
                    -moz-border-radius: 4px;
                    font-size: 1.6rem;
                }
            }
        }
    }

    .form_addto_custom_links {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-top: 45px;
        margin-left: auto;
        width: 45px;

        .action.towishlist {
            background: transparent !important;
            border: none !important;
            text-align: center;
            width: 45px;
            height: 45px;
            margin-left: auto;
            font-size: 18px !important;
            padding: 14px !important;

            &:active,
            &:focus,
            &:hover {
                color: $brand-green;

                &:before {
                    color: $brand-green;
                }
            }

            &:before {
                display: block !important;
                font-family: $font-family__resene-icons;
                content: $resene-icon-heart-empty;
            }

            span {
                display: none;
            }
        }
    }
}

/*NEW UI 2024*/
.catalog-product-view {
    &.product-view-testpot {

        $layout-gap: 20px;
        $layout-gap-m: 30px;
        $layout-gap-l: 40px;

        .column.main {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            column-gap: $layout-gap;

            @media (min-width: $screen-m) {
                column-gap: $layout-gap-m;
            }

            @media (min-width: $screen-l) {
                column-gap: $layout-gap-l;
            }

            .fotorama-item{
                .fotorama__wrap--slide {
                    .fotorama__arr {
                        @media (max-width: ($screen-m - 1) ) {
                            &:not(.fotorama__arr--disabled) {
                                transform: none;
                                opacity: 1;
                                display: flex !important;
                                align-items: center;
                                justify-content: center;
                                top: auto;
                                height: 50px;
                                width: 50px;
                                background-color: transparent;

                                &:not(:focus) {
                                    transform: none;
                                }

                                .fotorama__arr__arr {
                                    position: static;
                                    transform: none;
                                    width: 30px;
                                    height: 30px;
                                    background-color: rgba(255, 255, 255, 0.5);
                                    border-radius: 4px;
                                }

                                &.fotorama__arr--prev {
                                    .fotorama__arr__arr {
                                        background-position: -27px -266px;
                                    }
                                }

                                &.fotorama__arr--next {
                                    .fotorama__arr__arr {
                                        background-position: -24px -346px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .product-colour-title {
            width: 100%;
            margin-bottom: 15px;

            h2 {
                margin-top: 0;
                margin-bottom: 10px;
                font-weight: 400;
                font-size: 32px;
                line-height: 40px;

                @media (min-width: $screen-l) {
                    font-size: 36px;
                    line-height: 45px;
                }
            }

            label {
                font-weight: 400;
                font-size: 18px;
                line-height: 22.5px;

                @media (min-width: $screen-l) {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 25px;
                }
            }
        }

        .product-colour-media {
            width: 100%;

            @media (min-width: $screen-m) {
                width: calc(50% - (#{$layout-gap-m} /2));
            }

            @media (min-width: $screen-l) {
                width: calc(50% - (#{$layout-gap-l} /2));
            }

            .colour-placeholder {
                margin-bottom: 30px;

                @media (min-width: $screen-m) {
                    margin-bottom: 20px;
                }
            }

            .fotorama-item {
                .fotorama__wrap--slide {
                    .fotorama__stage__frame {
                        .fotorama__img {
                            width: 100%;
                        }
                    }

                    .fotorama__nav-wrap {
                        margin-top: 4px;

                        @media (max-width: ($screen-m - 1) ) {
                            display: none !important;
                        }

                        .fotorama_horizontal_ratio {
                            .fotorama__img {
                                width: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
        }

        .other-colours-type {
            .colour-media-notes {
                margin-bottom: 30px;
            }

            .product.data.items {
                margin-bottom: 30px;

                > .item.title {
                    @media (min-width: $screen-m) {
                        margin-right: 10px;
                    }

                    @media (min-width: $screen-l) {
                        margin-right: 15px;
                    }

                    > .switch {
                        height: auto !important;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        border: 1px solid #c2c2c2;
                        border-radius: 4px;
                        cursor: pointer;

                        @media (min-width: $screen-m) {
                            padding-left: 10px;
                            padding-right: 10px;
                        }

                        @media (min-width: $screen-l) {
                            padding-left: 20px;
                            padding-right: 20px;
                        }
                    }

                    @media (max-width: ($screen-m - 1)) {
                        > .switch {
                            &:after {
                                display: inline-block;
                                margin-left: 10px;
                                vertical-align: top;
                                float: right;
                                font-family: "resene-icons-font";
                                font-weight: normal;
                                speak: none;
                                text-align: right;
                                content: $resene-icon-down-dir;
                            }
                        }

                        &.active {
                            > .switch {
                                &:after {
                                    content: $resene-icon-up-dir;
                                }
                            }
                        }
                    }
                }

                > .item.content {

                    &.empty {
                        padding: 0 !important;
                        border: none;
                    }

                    @media (min-width: $screen-m) {
                        margin-top: 42px;
                        padding-right: 0 !important;
                        border-top: none;
                    }
                }
            }

            .complementary-colours-list {
                display: flex;
                flex-wrap: wrap;
                gap: $layout-gap;

                .item {
                    width: 100%;

                    @media (min-width: $screen-m) {
                        width: calc(50% - (#{$layout-gap} /2));
                    }

                    @media (min-width: $screen-l) {
                        width: calc((100% / 3) - ((#{$layout-gap}*2) /3));
                    }

                    a {
                        display: block;
                        height: 100px;
                        margin-bottom: 10px;
                    }
                }
            }

            .similar-colours-list {
                display: flex;
                flex-wrap: wrap;
                gap: $layout-gap;

                .item {
                    width: 100%;

                    @media (min-width: $screen-m) {
                        width: calc(50% - (#{$layout-gap} /2));
                    }

                    @media (min-width: $screen-l) {
                        width: calc((100% / 3) - ((#{$layout-gap}*2) /3));
                    }

                    a {
                        display: block;
                        height: 100px;
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .product-colour-details {
            display: flex;
            flex-wrap: wrap;
            column-gap: $layout-gap;
            width: 100%;

            @media (min-width: $screen-m) {
                width: calc(50% - (#{$layout-gap-m} /2));
            }

            @media (min-width: $screen-l) {
                width: calc(50% - (#{$layout-gap-l} /2));
            }
        }

        .product-colour-notes {
            width: 100%;

            .colour-notes {
                margin-bottom: 10px;
                overflow: hidden;
                transition: max-height 0.5s ease;

                &.expanded {
                    max-height: 1000px;
                }

                &.collapsed {
                    max-height: 80px;
                }
            }

            .read-more {
                text-decoration: underline;
            }
        }

        .product-colour-tabs {
            width: 100%;
            padding-top: 20px;
            padding-bottom: 20px;

            h3 {
                margin-top: 0;
                margin-bottom: 20px;
                font-size: 24px;
                font-weight: 500;
                line-height: 30px;
            }

            .tabs {
                display: flex;
                gap: 10px;
            }

            a.tab-switcher {
                padding: 10px 20px;
                border: 1px solid #c2c2c2;
                border-radius: 4px;
                color: #000 !important;
                font-size: 20px;
                font-weight: 400;
                line-height: 26px;
                cursor: default; //Disable click for now as no A4 Drawdown yet

                &:hover,
                &:active,
                &:visited {
                    color: #000;
                    text-decoration: none;
                }
            }
        }

        .product.media {
            width: 100%;
            display: block;
            order: initial;

            @media (min-width: $screen-m) {
                width: 100% !important;
            }

            @media (min-width: $screen-l) {
                width: calc(45% - (#{$layout-gap} /2)) !important;
                margin-top: 0;
                margin-bottom: 0;
                padding-left: 0;
                border: none;
            }

            .fotorama__caption {
                display: none;
            }

            .fotorama-item{
                .fotorama__wrap--slide {
                    @media (max-width: ($screen-m - 1) ) {
                        .fotorama__stage__frame {
                            .fotorama__img {
                                width: 100%;
                            }
                        }

                        .fotorama__arr {
                            .fotorama__arr__arr {
                                background-color: rgba(255, 255, 255, 0.8);
                                border: 1px solid #ccc;
                            }

                            &.fotorama__arr--disabled {
                                .fotorama__arr__arr {
                                    opacity: 0.5;
                                }
                            }
                        }
                    }
                }
            }
        }

        .product-info-main {
            @media (min-width: $screen-m) {
                width: 100% !important;
            }

            @media (min-width: $screen-l) {
                width: calc(55% - (#{$layout-gap} /2)) !important;
                margin-top: 0;
                margin-bottom: 0;
                padding-left: 0;
                border: none;
            }

            h1.page-title {
                margin-bottom: 10px;
                font-size: 20px;
                line-height: 26px;

                @media (min-width: $screen-m) {
                    margin-top: 0;
                }

                @media (min-width: $screen-l) {
                    font-size: 20px;
                    line-height: 26px;
                }
            }

            .product-info-stock-sku {
                margin-bottom: 30px;

                .stock {
                    font-weight: 400;
                    font-size: 12px;
                }
            }

            .box-tocart {
                margin-top: 0;
            }

            #product_addtocart_form {
                .form_addto_custom_links {
                    margin-top: 25px;
                }
            }
        }

        .product.attribute {
            &.sku {
                .type {
                    display: none;
                }
            }
        }

        .custom-store-locator-container {
            width: 100%;
            margin-top: 20px;

            #check-available-location-button {
                max-width: 300px;
            }
        }

        .block.related {
            width: 100%;

            @media (min-width: $screen-l) {
                border-top: none;
            }
        }
    }

    .block.related {
        .product-items {
            display: flex;
            flex-wrap: wrap;
            margin-left: 0;
            margin-right: 0;
            gap: 20px;

            .product-item {
                padding: 0;
                width: calc(50% - (20px /2));

                @media (min-width: $screen-m) {
                    width: calc((100% / 3) - (40px /3));
                }

                @media (min-width: $screen-l) {
                    width: calc((100% / 4) - (60px /4));
                }

                @media (min-width: $screen-xl) {
                    width: calc((100% / 5) - (80px /5));
                }

                .product-item-details {
                    > .product-item-name {
                        min-height: 40px;
                        line-height: 20px;

                        a {
                            line-height: inherit;
                        }
                    }
                }

                .product-item-actions {
                    display: flex !important;
                    align-items: flex-end;
                    width: 100%;
                    overflow: hidden;
                    line-height: 1;

                    .product-item-name {
                        display: inline-flex;
                        float: none;
                        width: 90px;
                        min-height: auto;
                        margin: 0;
                        word-break: keep-all;
                        -webkit-hyphens: none;
                        hyphens: none;
                    }

                    .actions-secondary {
                        display: inline-flex;
                        float: none;
                        width: calc(100% - 90px);
                        padding-left: 10px;
                    }
                }
            }
        }
    }
}

.buy-paint-container {
    position: relative;
    padding: 40px 0;
    width: 100%;
    background-color: $bg-light-gray;

    &:before {
        position: absolute;
        top: 0;
        left: 50%;
        width: 100vw;
        height: 100%;
        transform: translateX(-50%);
        background: inherit;
        content: '';
    }

    &:after {
        position: absolute;
        top: 0;
        right: 50%;
        width: 100vw;
        height: 100%;
        transform: translateX(-50%);
        background: inherit;
        content: '';
    }

    > * {
        position: relative;
    }

    h3 {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 28px;
        font-weight: 500;

        @media (min-width: $screen-m) {
            font-size: 32px;
        }
    }

    .paint-filters {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        @media (min-width: $screen-l) {
            gap: 40px;
        }

        > div {
            position: relative;
            display: inline-flex;
            flex-wrap: wrap;
            flex-direction: column;
            width: 100%;

            @media (min-width: $screen-m) {
                width: calc((100% / 2) - 10px);
            }

            @media (min-width: $screen-l) {
                width: calc((100% / 2) - 20px);
            }

            @media (min-width: $screen-xl) {
                width: calc((100% / 3) - (80px / 3));
            }

            &:not(:last-child) {
                &:after {
                    position: absolute;
                    top: 0;
                    height: 100%;
                    width: 1px;
                    content: '';

                    @media (min-width: $screen-xl) {
                        right: -20px;
                        border-right: 1px solid #ccc;
                    }
                }

                &:nth-child(2) {
                    &:after {
                        @media (max-width: ($screen-l -1)) {
                            border-right: none;
                        }
                    }
                }
            }

            &.category-filters {
                @media (max-width: ($screen-xl - 1)) {
                    width: 100%;
                    max-width: 620px;
                }
            }
        }

        label {
            margin-bottom: 10px;
            font-size: 24px;
            font-weight: 500;
            line-height: 30px;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            gap: 10px;
            margin: 0;
            padding: 0;

            li {
                position: relative;
                margin: 0;
                display: inline-flex;
                width: calc(50% - 5px);

                @media (min-width: $screen-m) {
                    width: calc((100% / 3) - (20px / 3));
                }

                a, select,
                label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-height: 56px;
                    padding: 8px 6px;
                    border: 1px solid #000;
                    border-radius: 4px;
                    color: #000;
                    font-size: 18px;
                    line-height: 20px;
                    text-align: center;
                    outline: none;
                }

                label {
                    position: relative;
                    width: 100%;
                    z-index: 2;
                    margin: 0;
                    cursor: pointer;

                    &.required {
                        border-color: #ed8380;
                    }
                }

                select {
                    width: 100%;
                    height: 58px;
                    text-align: left;
                    cursor: pointer;

                    &:focus {
                        box-shadow: none;
                    }
                }

                input[type='radio'],
                input[type='checkbox'] {
                    position: absolute;
                    opacity: 0;

                    &:checked + label {
                        border-color: $brand-dark-green;
                        background-color: $brand-dark-green;
                        color: #fff;
                    }

                    &:disabled + label {
                        border-color: $border-color-disabled;
                        background-color: $bg-color-disabled;
                        color: $text-color-disabled;
                        cursor: not-allowed;
                    }
                }
            }
        }

        .type-filters {
            ul {
                li {
                    width: calc(50% - 5px);

                    &.category {
                        width: 100%;
                    }
                }
            }
        }

        label[class*=filter_interior] {
            &:before {
                display: inline-block;
                margin-right: 10px;
                vertical-align: top;
                font-family: "resene-icons-font";
                font-size: 32px;
                font-weight: normal;
                speak: none;
                text-align: right;
                content: $resene-icon-paint-interior;
            }
        }

        label[class*=filter_exterior] {
            &:before {
                display: inline-block;
                margin-right: 10px;
                vertical-align: top;
                font-family: "resene-icons-font";
                font-size: 32px;
                font-weight: normal;
                speak: none;
                text-align: right;
                content: $resene-icon-paint-exterior;
            }
        }

    }
}

.buy-paint-results {
    width: 100%;
    overflow: hidden;

    ul.products.list {
        padding-bottom: 80px;
        border-bottom: 1px solid $border-color-disabled;

        .slick-slide {
            padding: 0 10px;
        }

        li.product-item {
            padding: 0;
            margin: 0;

            .product-item-info {
                max-width: 250px;
                margin: auto;
            }
        }

        .slick-dots {
            bottom: 30px;

            li {
                button {
                    width: 13px;
                    height: 13px;
                    background-color: #000000;
                    opacity: 0.2;
                }

                &.slick-active {
                    button {
                        opacity: 0.6;
                    }
                }
            }
        }
    }

    .product-item-actions {
        .action.primary {
            min-height: 44px;
            line-height: 1;
            background: $brand-dark-green;
            border-color: $brand-dark-green;
            border-radius: 4px;
            font-family: "Lexend", sans-serif;
            font-size: 15px;
            padding: 13px 17px;

            &:active,
            &:hover {
                background-color: $brand-dark-green-hover;
                border-color: $brand-dark-green-hover;
            }
        }
    }
}


