#html-body {

    #breadcrumbs {
        margin: 0 auto;
        max-width: calc(1280px + 40px); 
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-left: $side-padding-m;
        padding-right: $side-padding-m;
        @media (max-width: 767px) {
            margin-top: $header-height-mobile;
        }
        @media (min-width: 768px) {
            padding-left: $side-padding-t;
            padding-right: $side-padding-t;
            margin-top: $header-height-desktop;
        }
        ul {
            font-size: 13px;
            li {
                &:not(:last-child):after {
                    color: black;
                    font-size: 25px;
                    position: relative;
                    top: 2px;
                }
                a {
                    color: black;
                }
                strong {
                    color: #909090;
                }
            }
        }     
    }

}