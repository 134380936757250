#html-body {

    .header-local,
    #AU_customers_in_NZ_store,
    #foreign_customers_in_NZ_store,
    #NZ_customers_in_AU_store,
    #foreign_customers_in_AU_store,
    #cross_site_banner {
        display: none !important;
    }

    .header-local {
        background-color: $brand-blue;
        color: $white;
    }

    .geoip_field_container {
        background-color: $brand-green !important;
        display: inline-block;
        max-width: none;
        padding: 0;
        .row {
            margin-right: 0 !important;
            margin-left: 0 !important;
        }
        #geoip_field {
            color: $white;
            text-align: center;
    
            h4 {
                font-weight: normal;
                line-height: 1.75em;
            }
    
            a {
                color: $white !important;
                text-decoration: underline;
            }
        }
    }


    #cross_site_banner {
        background: $brand-yellow;
        color: $white;
    }

    .minicart-wrapper {
        z-index: 99999;
        margin: 0px;
        @media (max-width: 767px) {
            position: absolute;
            right: $side-padding-m;
            top: 30px;
            z-index: 900;
        }
        @media (min-width: 768px) {
            right: -40px;
        }

        .action.showcart {
            &:before {
                content: '' !important;
                display: block !important;
                color: white !important;
                font-size: 44px !important;
                font-family: inherit;
                background: url('../images/icons/icon-feather-shopping-cart-white.svg') no-repeat no-repeat transparent;
                background-size: contain;
                width: 30px;
                height: 29px;
                @media (max-width: 767px) {
                    width: 23px;
                    height: 22px;
                }
            }
            .counter.qty {
                position: absolute;
                top: -27px;
                right: -4px;
                margin: 4px 0 0;
                font-size: 12px;
                line-height: 21px;
                height: 22px;

                .loading-mask {
                    .loader {
                        > img {
                            max-width: 100%;
                        }
                    }
                }
            }
        }

        // Popup minicart
        .block-minicart {
            top: 100%;
            width: calc(100vw - 42px);
            max-width: 390px;
            @media only screen and (min-width: 768px) {
                width: 390px;
            }

            &:before,
            &:after {
                left: auto;
            }
            &:before {
                right: 18px;
                @media only screen and (max-width: 767px) {
                    right: 2px;
                }
            }
            &:after {
                right: 17px;
                @media only screen and (max-width: 767px) {
                    right: 1px;
                }
            }
        
            li.product-item {
                .product-image-container {
                    width: 70px !important;
                }
                .product-item-details {
                    .product-item-name {
                        min-height:initial;
                        font-weight: bold;
                        font-size: 18px;
                        margin-bottom: 0;
                    }
                    @media (max-width: 1140px) {
                        .product-item .product-item-name {
                            min-height: initial;
                        }
                    }
                    .options {
                        width: 140px;
                        margin-left: 0;
                    }
                    a {
                        color: $brand-blue;
                        &:visited {
                            color: $brand-blue;
                        }
                    }
                    .details-qty {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                    }
                }
            }
        
            .minicart-items-wrapper {
                height: 280px !important;
            }
        
            .minicart-items {
                .update-cart-item {
                    @include btn();
                    @include btn-grey();
                    font-size: 1.2rem;
                    padding: 7px 20px;
                    min-height: 32px;
                    @media (max-width: 767px) {
                        margin-top: 1em;
                        flex: 0 1 170px;
                        margin-top: 2em;
                    }
                }
                .action.delete {
                    &:hover,
                    &:focus,
                    &:active {
                        &:before {
                            text-decoration: underline;
                        }
                    }
        
                    &:before {
                        content: 'Remove';
                        color: $brand-blue;
                        font-family: 'Open Sans';
                        font-size: 15px;
                        line-height: 33px;
                    }
                }
            }
        
            .price-container {
                display: inline;
            }
        
            .actions {   
                margin-top: 12px;
                .action.viewcart {
                    color: $brand-blue;
                    position: relative;
                    top: 6px;
                }
            }

            .minicart-widgets {
                margin-top: 0px;
            }
        
        }

    }
}



.authentication-wrapper {
    .action-auth-toggle {
        margin-top: 80px;
    }
}

.shippingflagcontainer {

    max-width: 2140px;

    #shipping_flag {
        border-radius: 5px;
        height: 50px;
        padding-bottom: 80px;
        padding-top: 0;
        padding-left: 40px;
        margin-top: 2rem;
        margin-bottom: 2rem;
        margin-left: 0;
        background-color: #e5efe5;
        color: #006400;
        border: 1px solid;
    }
}

@media (min-width: 840px) {

    .shippingflagcontainer {

        max-width: 2140px;

        #shipping_flag {
            padding-bottom: 60px;
        }
    }
}


.salesflagcontainer {

    max-width: 2140px;

    #sales_flag {
        border-radius: 5px;
        margin-left: 0;
        background-color: #e5efe5;
        color: #006400;
        border: 1px solid;
        margin-top: 15px;
        margin-bottom: 10px;
    }
}

@media (min-width: 840px) {

    .salesflagcontainer {

        max-width: 2140px;

        #sales_flag {
            border-radius: 5px;
            margin-left: -10px;
            background-color: #e5efe5;
            color: #006400;
            border: 1px solid;
            margin-top: 15px;
        }
    }
}



div.login-status {

    &.is-guest {
        & ~ .page-wrapper {
            ul.header.links {
                li {
                    display: none !important;
                    &.authorization-link {
                        display: inline-block !important;
                        @media (max-width: 767px) {
                            display: block !important;
                        }
                    }
                    &:last-child {
                        display: inline-block !important;
                        @media (max-width: 767px) {
                            display: block !important;
                        }
                    }
                }
            }
        }
    }

    &.is-logged-in {
        & ~ .page-wrapper {
            ul.header.links {
                > li {
                    display: none !important;
                    &.greet.welcome {
                        display: inline-block !important;
                        @media (max-width: 767px) {
                            display: block !important;
                        }
                    }
                    a {
                        &:hover,
                        &:focus,
                        &:active {
                            text-decoration: none;
                        }
                    }
                }
                a.customer-dropdown-menu {
                    padding-left: 0px;
                    &.is-shown {
                        & + ul.customer-dropdown-menu-links {
                            display: block;
                        }
                    }
                }
                ul.customer-dropdown-menu-links {
                    display: none;
                    list-style: none;
                    margin: 0px;
                    padding: 0px;
                    position: absolute;
                    z-index: 9999999;
                    background: $dark-grey;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                    width: calc(100% - 1.5rem);
                    min-width: 111px;
                    max-width: 111px;
                    top: 31px;
                    right: 0px;
                    @media (max-width: 767px) {
                        max-width: none;
                    }
                    li {
                        a {
                            display: inline-block;
                            width: calc(100%);
                            margin: 0px !important;
                            padding: 0.5rem 1.5rem;
                        }
                    }
                }
            }
        }
    }
    
}

header {
    
    &.page-header {
        background-color: $black;
        margin-bottom: 0;
        @media (max-width: 767px) {
            z-index: 50;
            position: fixed;
            width: 100%;
            height: $header-height-mobile;
        }
        @media (min-width: 768px) {
            z-index: 50;
            position: fixed;
            width: 100%;
            height: $header-height-desktop;
        }
    }

    .panel {
        background-color: $black;
        &.wrapper {
            border-bottom: 1px solid #525252 !important;
            .header {
                max-width: calc(1280px + 40px);
            }
        }
        ul.header.links {
            li {
                margin: 0px !important;
                a {
                    margin: 0 0 0 15px !important;
                    color: white !important;
                    &.create-an-account {
                        color: $brand-green !important;
                    }
                }
                &.greet.welcome {
                    position: relative;
                    color: white !important;
                    margin: 0 0 0 0px !important;
                    span {
                        position: relative;
                        &:after {
                            display: inline-block;
                            content: '';
                            background: url('../images/icons/icon-ionic-md-arrow-dropdown-green.svg') no-repeat transparent;
                            background-size: contain;
                            width: 10px;
                            height: 6px;
                            margin-left: 1rem;
                            position: relative;
                            top: -1px;
                        }                       

                    }
                }
            }
        }
    }

    .header.content {
        max-width: calc(1280px + 40px);
        padding-top: 2.6rem;
        @media (min-width: 768px) {
            padding: 4.1rem 6rem 0rem $side-padding-t;
        }

        .logo {
            margin-left: -9px;
            @media (max-width: 767px) {
                margin: 0 0 0px 40px;
                left: calc(50% - 50px);
                translate: -50%;
                top: -9px;
            }
            @media (min-width: 768px) {
                top: -8px;
                margin-top: -20px;
                margin-bottom: 0px;
            }
            img {
                width: 100%;
                max-width: 302px;
                @media (max-width: 767px) {
                    max-height: 58px;
                }
            }
        }
    }
}



.block-search {
    input {
        &#search {
            border: 0 !important;
            height: 36px;
            font-size: 16px !important;
        }
    }
    .label {
        @media (max-width: 767px) {
            float: none;
            position: absolute;
            right: 55px;
            top: 8.6vw;
            &:before {
                content: '';
                font-family: inherit;
                background: url('../images/icons/icon-ionic-ios-search.svg') no-repeat transparent;
                background-size: contain;
                width: 20px;
                height: 20px;
            }
        }

        &:active,
        &:hover {
            &:before {
                color: #8f8f8f !important;
            }
        }
    }
}




nav.navigation {
    //margin-left: 48px;
    .dropdown-menu {
        top: 0 !important;

        @media (min-width: 768px) {
            top: 50px !important;
        }

        li.all-category {
            display: none;
        }
    }

}

@media (min-width: 768px) {
    .sections.nav-sections {
        display: none; // TEMPORARY DISABLE TOP MENU
        background: none;
        margin-top: -53px;
    }

    nav.navigation {
        background: none;

        // TODO: Used !important as a temporary fix to override styles from styles-l.css (which is loaded after these styles)
        // Possible to remove styles-l from the theme in htdocs/app/design/frontend/Springtimesoft/Resene/Magento_Theme/layout/default.xml, see https://stackoverflow.com/questions/41344072/magento-2-how-to-remove-styles-m-css-from-category-and-product-page
        li.level0 {
            margin-right: 0;
            > a.level-top {
                color: $white !important;
                display: block !important;
                width: 180px !important;
                text-align: center;
                margin-right: -6px;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: 0.5px;
                border: none !important;
            }
            > a.level-top:hover,
            > a.level-top.ui-state-active {
                background: $white !important;
                color: $dark-grey !important;
                border: none !important;
            }
        }

        li {
            a.home_tab {
                background: $brand-blue;
                color: $white;
            }
            a.shopbycolour_tab {
                background: $brand-green;
                color: $white;
            }
            a.shopbyproduct_tab {
                background: $brand-yellow;
                color: $white;
            }
            a:hover,
            a.ui-state-active {
                background: $white !important;
                color: $dark-grey !important;
            }
        }

        .dropdown-menu {
            position: absolute;
            top: 100%;
            left: 0 !important;
            z-index: 1000;
            display: none !important;
            float: left;
            min-width: 180px;
            padding: 5px 0;
            margin: 2px 0 0;
            list-style: none;
            font-size: 18px;
            text-align: center;
            background-color: #fff;
            border-radius: 0;
            -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
            background-clip: padding-box;

            li a {
                display: block;
                padding: 12px;
            }
        }
        .open > .dropdown-menu,
        .ui-state-active + .dropdown-menu {
            display: block !important;
        }

        // Override dropdown colours
        .drop-colour {
            color: $brand-green !important;

            &:hover {
                color: #686868 !important;
                text-decoration: underline;
            }

        }

        .drop-product {

            color: $brand-yellow !important;

            &:hover {
                color: #686868 !important;
                text-decoration: underline;
            }

        }

    }

    .caret:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        line-height: 1;
        content: "\e607";
        font-family: 'icons-blank-theme';
        font-size: 3rem;
        vertical-align: middle;
        font-weight: bold;
        speak: none;
        text-align: center;
    }
    .ui-state-active .caret:after {
        content: "\e618";
    }
}

@media not all and (any-pointer: fine) {
    nav.navigation li a.home_tab:hover {
        background: $brand-blue !important;
        color: $white !important;
    }
    nav.navigation li a.shopbycolour_tab:hover {
        background: $brand-green !important;
        color: $white !important;
    }
    nav.navigation li a.shopbyproduct_tab:hover {
        background: $brand-yellow !important;
        color: $white !important;
    }
    nav.navigation li a.ui-state-active,
    nav.navigation li a.ui-state-active:hover {
        background: $white !important;
        color: $dark-grey !important;
    }
}
