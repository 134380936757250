//
// Footer
//
#footer-sign-up {
  background-color: #1f2226;
  height: auto;
  padding-top: 2rem;
  padding-bottom: 0rem;

  @media (min-width: 768px) {
    padding-bottom: 4rem;
  }

  /* Membership signup */
  .footer-inputs {

    padding-top: 0;

    h3 {
      color: white;
      font-weight: bold;
    }

  }

  .sign-up_buttons {
    color: white;

  }

  .footer-logo {
    height: 100px;
    margin-top: 12px;
    background-image: url("../images/resene-logo-white.png");
    background-repeat: no-repeat;
    background-size: 220px;

    @media (max-width: 767px)  {
      margin-left: 15px;
    }
  }

}

footer {
  margin-top: 30px;
  .row {
    margin-left: 0px;
    @media (max-width: 767px)  {
      margin-right: 0px;
    }
  }

  ul li a {
    font-weight: 600;
  }

}

.details_text {
  padding-top: 22px;
  padding-left: 5px;
  font-size: 46px;
  font-weight: 600;
}

.container-1 {

  input#email_input {

    width: 95%;
    height: 50px;
    margin-top: 20px;
    margin-left: 3%;
    //background: $brand-colour3;
    border: none;
    font-size: 10px;
    color: #63717f;
    padding-left: 20px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;

    //@include tablet {
    //
    //  margin-top: 0;
    //  margin-left: 0;
    //
    //}

  }

  .icon {

    position: absolute;
    top: 50%;
    margin-left: 17px;
    margin-top: 17px;
    z-index: 1;
    color: #4f5b66;

  }

}

.sign-up_buttons {

  font-weight: 600;
  width: 100%;
  height: 50px;
  //margin-left: 3%;
  //margin-top: 20px;
  //color: $brand-colour3;
  background: rgba(255,255,255,0.2);
  border: 3px solid rgba(225,225,225,0.9);
  font-size: 16px;

  //@include tablet {
  //  max-width: 400px;
  //  margin-top: 0;
  //  margin-left: 0;
  //
  //}

}

.container-1 input#email_input::-webkit-input-placeholder {

  color: #65737e;

}

.container-1 input#email_input:-moz-placeholder { /* Firefox 18- */

  color: #65737e;

}

.container-1 input#email_input:-moz-placeholder { /* Firefox 19+ */

  color: #65737e;

}

.container-1 input#email_input:-ms-input-placeholder {

  color: #65737e;

}

//
// Footer section
//

// Separation of link text
footer[role="contentinfo"] {
  .footer-links {
    padding-left: 0px;
    @media (max-width: 767px) {
      text-align: center;
    }
  }

  .trusted-brand-logos {
    @media (max-width: 767px) {
      text-align: center;
    }
  }

  .footer-links-right {
    padding-left: 0px;
    @media (max-width: 767px) {
      text-align: center;
    }
  }

  .footer-disclaimer {
    margin-bottom: 24px;
    margin-top: 24px;
    padding: 0px;
    z-index: 1;

    @media (min-width: 768px) {
      float: right;
      margin-top: 0px;
    }
  }


  ul.links {
    list-style: none;
    float:left;
    padding-left: 0px;
    @media (max-width: 767px) {
      display: flex;
      justify-content: center;
      text-align: center;
      width: 100%;
    }
    @media (min-width: 768px) {
      float: right;
    }

    li.last {
      float: left;
      padding-right: 12px;
      padding-left: 0px;
      &:last-child {
        padding-right: 0;
      }
    }

  }



}
