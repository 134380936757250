#html-body {

    &.mobile-menu-filter-header-is-active {
        @media (max-width: 767px) {
            overflow: hidden;
        }
        .header.content {
            @media (max-width: 767px) {
                display: none;
            }
        }
    }


    .sidebar {

        // Amasty Layered NAV
        .block.filter {
            $pad: 2rem;
            @media (max-width: 767px) {
                margin-bottom: 20px;
            }
        
            .block-title.filter-title {
                line-height: 1;
                padding: 0px;
                @media (max-width: 767px) {
                    margin: 0px;
                }
                strong {
                    display: none;
                }
                .mobile-shop-by-button {
                    @media (max-width: 767px) {
                        cursor: pointer;
                        display: inline-block;
                        font-size: 16px;
                        background: #F0F0F0;
                        border-radius: 4px;
                        text-align: center;
                        width: calc(50% - 3rem);
                        max-width: 154px;
                        margin-top: 0px;
                        line-height: 1;
                        padding: 12px 0rem;
                        min-height: 40px;
                        max-height: 40px;
                    }
                }
            }
            .block-content.filter-content { 
                .filter-current {
                    position: relative;
                    font-size: 16px;
                    @media (max-width: 767px) {
                        cursor: pointer;
                        border: solid #ccc;
                        border-width: 1px 0;
                        margin-top: 3.2rem;
                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            right: 1px;
                            top: 18px;
                            background: url('../images/icons/icon-feather-chevron-down.svg') no-repeat transparent;
                            background-size: contain;
                            width: 13px;
                            height: 8px;
                            rotate: 0deg;
                        }
                        &.is-active {
                            display: block;
                            border-width: 1px 0 0;
                            &:after {
                                rotate: 180deg;
                            }
                            .items {
                                display: block;
                            }
                            & + .block-actions.filter-actions {
                                display: block;
                                border-bottom: 1px solid #ccc;
                                padding-bottom: 1.5rem;
                            }
                        }
                        .items {
                            display: none;
                        }
                    }
    
                    > .block-subtitle {
                        display: block;
                        font-size: 18px;
                        margin-bottom: 1.5rem;
                        margin-top: -1rem;
                        @media (max-width: 767px) {
                            font-size: 16px;
                            margin-top: 1rem;
                            margin-bottom: 1rem;
                            &:after {
                                content: ' (' attr(data-count) ')';
                                font-weight: normal;
                                color: $brand-blue;
                            }
                        }
                    }
    
                    .items {
                        .item {
                            position: relative;
                            background: #F0F0F0;
                            border-radius: 4px;
                            padding: 0.81rem 3.5rem 0.81rem 1.5rem;
                            line-height: 1;
                            .filter-label {
                                display: inline-block;
                                font-size: 14px;
                                font-weight: normal;
                            }
                            .filter-value {
                                font-size: 14px;
                                line-height: 1.2;
                            }
                            .action.remove {
                                position: absolute;
                                right: 7px;
                                top: 5px;
                                text-indent: -999999px;
                                width: 25px;
                                height: 25px;
                                span {  
                                    &:after {
                                        content: '';
                                        display: block;
                                        position: absolute;
                                        right: 9px;
                                        top: 8px;
                                        background: url('../images/icons/icon-ionic-ios-close.svg') no-repeat transparent;
                                        background-size: contain;
                                        width: 8px;
                                        height: 8px;
                                    }                               
                                }
                            }
                        }
                    }

                    .amshopby-items.items{
//                        .amshopby-item[data-container="resene_base_id"]:nth-of-type(n+2) {
                            .amshopby-item[data-container="resene_base_id"] {
                            display: none;
                        }
                    }
                }
                .block-actions.filter-actions {
                    font-size: 16px;
                    margin-top: 1.5rem;
                    margin-bottom: 1.8rem;
                    @media (max-width: 767px) {
                        display: none;
                    }
                    a {
                        text-decoration: underline;
                    }
                }
                .block-subtitle.filter-subtitle { // Hide Shopping Options
                    display: none;
                }


                $mobile-menu-filter-padding: 2rem;
                .mobile-menu-filter-header {
                    display: none;
                    @media (max-width: 767px) {
                        position: relative;
                        display: block;
                        background: $black;
                        font-size: 16px;
                        font-weight: 700;
                        color: white;
                        padding-top: 2.8rem;
                        padding-bottom: 2.8rem;
                        padding-left: $mobile-menu-filter-padding;
                        padding-right: $mobile-menu-filter-padding;
                        margin-bottom: 3.3rem;
                    }
                    .close-icon {
                        display: block;
                        cursor: pointer;
                        position: absolute;
                        z-index: 999999;
                        right: 7px;
                        top: 50%;                        
                        transform: translateY(-50%);
                        padding: 1.5rem;
                        .close-icon-wrapper {
                            display: block;
                            background: url('../images/icons/icon-ionic-md-close-yellow.svg') no-repeat transparent;
                            background-size: contain;
                            width: 15px;
                            height: 15px;
                        }
                    }
                }
                dl.filter-options {
                    cursor: pointer;
                    @media (max-width: 767px) {
                        display: none;
                        background: #fff;
                        position: fixed;
                        right: 0;
                        top: 0px;
                        bottom: 0;
                        left: 0;
                        z-index: 10;
                        overflow-y: auto;
                        overflow-x: hidden;
                        &.is-active {
                            display: block;
                        }
                    }
    
                    dt.filter-options-title { // 1st level
                        position: relative;
                        font-size: 16px;
                        line-height: 1.2;
                        border-top: 1px solid $border-line-grey;
                        border-bottom: 1px solid $border-line-grey;
                        padding: #{$pad} #{$pad} calc(#{$pad} + 1px) 0;
                        margin: 0;                   
                        margin-bottom: -1px;
                        word-break: initial;
                        @media (max-width: 767px) {
                            margin-left: $mobile-menu-filter-padding;
                            margin-right: $mobile-menu-filter-padding;
                        }
                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            right: 1px;
                            top: 50%;
                            transform: translateY(-50%);
                            transform-origin: top;
                            background: url('../images/icons/icon-feather-chevron-down.svg') no-repeat transparent;
                            background-size: contain;
                            width: 13px;
                            height: 8px;
                            rotate: 0deg;
                        }
                        &.is-active {
                            border-bottom: none;
                            &:after {
                                rotate: 180deg;
                            }
                            & + dd.filter-options-content {
                                display: block;
                                border-bottom: 1px solid $border-line-grey;
                                padding-bottom: #{$pad};
                                margin-bottom: -1px;
                                @media (max-width: 767px) {
                                    margin-left: $mobile-menu-filter-padding;
                                    margin-right: $mobile-menu-filter-padding;
                                }
                            }
                        }
                        & + dd.filter-options-content {
                            display: none;
                        }
                    }
                    dd.filter-options-content {

                        // General styles
                        .amshopby-choice {
                            cursor: pointer;
                            padding-right: 1rem;
                        }
                        input[type="checkbox"] {
                            cursor: pointer;
                            appearance: none;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            width: 14px;
                            height: 14px;
                            border: 1px solid #C9C9C9;
                            border-radius: 2px;
                            outline: none;
                            margin-top: 4px;
                            &:focus,
                            &:hover,
                            &:active {
                                outline: none;
                                box-shadow: none;
                            }
                            &:checked {
                                outline: none;
                                position: relative;
                                background-color: green;
                                &::after {
                                    //content: "\2713";
                                    content: '';
                                    display: block;
                                    background: url('../images/icons/icon-checkbox-ticked.svg') no-repeat transparent;
                                    background-size: contain;
                                    width: 16px;
                                    height: 16px;
                                    font-size: 12px;
                                    color: white;
                                    transform: translate(-50%,-50%);
                                    filter: invert(1) brightness(1);
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                }
                            }
                        }
                        .items { // 2nd level
                            margin: 0px;
                            padding: 0px;
                            width: 100%;
                            li {
                                position: relative;
                                display: flex;
                                flex-wrap: wrap;
                                align-items: start;
                                &.-is-collapsible {
                                    // TODO: Need to make as div, to make ul.items-children.is-active
                                    // &:after {
                                    //     content: '';
                                    //     display: block;
                                    //     position: absolute;
                                    //     right: 0px;
                                    //     top: 50%;
                                    //     transform: translateY(-50%);
                                    //     transform-origin: top;
                                    //     background: url('../images/icons/icon-feather-chevron-down.svg') no-repeat transparent;
                                    //     background-size: contain;
                                    //     width: 13px;
                                    //     height: 8px;
                                    //     rotate: 0deg;
                                    // }
                                }
                                a {
                                    order: 1;
                                    font-size: 16px;
                                    color: black;
                                    width: 82%;
                                    &:hover,
                                    &:focus,
                                    &:active {
                                        text-decoration: none;
                                    }
                                }
                                ul.items-children { // 3rd level
                                    display: none; // TODO: DISABLED 3rd level for now
                                }
                            }
                        }
    
    
                        // Specific styles
                        .items {
                            li {
                                a {
    
                                }
                                > .items-children {
                                    li {
    
                                    }
                                }
                            }
                        }
                    }
                }
            }
    
        }
    }  



}