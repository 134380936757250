#html-body {  

    &.checkout-cart-index {

        // Margin top allowance for sticky header
        main.page-main {
            @media (max-width: 767px) {
                margin-top: $header-height-mobile !important;
            }
            @media (min-width: 768px) {
                margin-top: $header-height-desktop !important;
            }
        }

        // Links
        .action.continue {
            background: none;
            color: $brand-blue;
            padding: 0;
            border: none;
            &:hover {
                background: none;
                border: none;
                color: $brand-blue;
            }
        }

        // Buttons
        .ampickup-choosemap {
            @include btn();
            @include btn-secondary();
            display: inline-block;
            font-size: 13px;
            min-height: 38px;
            padding: 11px 17px;
            @media only screen and (max-width: 767px) {
                width: 100%;
                text-align: center;
                font-size: 1.6rem;
                min-height: 45px;
                padding: 13px 17px;
            }
        }




        table#shopping-cart-table {
            .product-item-name {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 0;
        
                a {
                    color: $black;
                }
            }
        
            .price-including-tax, .price-excluding-tax {
                font-size: 16px;;
            }
        
            .item-options {
                font-size: 14px;
                margin-bottom: 0;
            }
        }

        .cart-summary {
            > .title {
                font-weight: bold;
            }
            .block {
                margin-top: 20px;
            }

            #block-shipping,
            #block-discount {
                > .title {
                    &:after {
                        content: '\e621'; // up arrow
                        font-size: 30px;
                        font-weight: bold;
                        line-height: 12px;
                        right: -5px;
                        top: 10px;
                    }
                }
                &.active > {
                    .title {
                        &:after {
                            content: '\e622'; // down arrow
                        }
                    }
                }
            }
            

            #block-shipping-heading {
                font-size: 16px;
            
                .cart-totals {
                    .mark strong {
                        font-weight: bold;
                    }
                }
            
                .tr.grand.totals.incl {
                    border-top: 1px solid;
                }
            
                .tr.grand.totals.excl {
                    .mark {
                        padding-bottom: 20px;
                    }
                }
            }
            
            .action.apply.primary {
                @include btn();
                @include btn-grey();
                display: inline-block;
                min-height: 34px;
                font-size: 15px;
                font-weight: bold;
                padding: 9px 19px;
                text-decoration: none;
            }

        }

    }

}


/* Popup minicart */
// .block-minicart {
//     &:before,
//     &:after {
//         left: auto;
//     }
//     &:before {
//         right: 25px;
//     }
//     &:after {
//         right: 26px;
//     }

//     li.product-item {
//         .product-image-container {
//             width: 70px !important;
//         }
//         .product-item-details {
//             .product-item-name {
//                 min-height:initial;
//                 font-weight: bold;
//                 font-size: 18px;
//                 margin-bottom: 0;
//             }
//             @media (max-width: 1140px) {
//                 .product-item .product-item-name {
//                     min-height: initial;
//                 }
//             }
//             .options {
//                 width: 140px;
//                 margin-left: 0;
//             }
//             a {
//                 color: $brand-blue;
//                 &:visited {
//                     color: $brand-blue;
//                 }
//             }
//         }
//     }

//     .minicart-items-wrapper {
//         height: initial;
//     }

//     .minicart-items {
//         .update-cart-item {
//             @include btn();
//             @include btn-grey();
//             font-size: 1.2rem;
//             padding: 7px 20px;
//             min-height: 32px;
//             @media (max-width: 767px) {
//                 margin-top: 1em;
//             }
//         }
//         .action.delete {
//             &:hover,
//             &:focus,
//             &:active {
//                 &:before {
//                     text-decoration: underline;
//                 }
//             }

//             &:before {
//                 content: 'Remove';
//                 color: $brand-blue;
//                 font-family: 'Open Sans';
//                 font-size: 15px;
//                 line-height: 33px;
//             }
//         }
//     }

//     .price-container {
//         display: inline;
//     }

//     .actions {
//         .action.viewcart {
//             color: $brand-blue;
//         }
//     }

// }

#cfc-offset-widget {
    padding-left: 0 !important;
    padding-right: 0 !important;
	@media (min-width: 768px) {
        width: 73% !important;
	}
}