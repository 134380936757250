// Pop up Modal styles
#paint_sample_modal {

  /*
  .complementary-section{
    height: 235px;
  }
  */

  div{
    text-align: left;
  }

  h3{
    margin-top: 0;
  }

  #sample_colour {
    height: 135px;
    width: 135px;
    margin-top: 20px;
  }

  .colour_title {
    text-align: left;
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  .colour_notes {
    text-align: left;
  }

  .shop-by-colour-modal {
    background-color: #009846;
    border: 0 solid #009846;
    border-radius: 0;
    color: white;
    font-weight: 600;
    font-size: 18px;
    width: 180px;
    margin-bottom: 20px;
    margin-top: 20px;
    height: 40px;
  }

  .complementary-colours-box{
    padding-left: 0;
    float: left;
    .complementary_colour_sample{
      margin-bottom: 10px;
      margin-top: 13px;
      border-radius: 0;
      width:75px;
      height:75px;
      border:none;
    }
  }

  .shop-by-colour-modal:hover {
    background-color: darken(#009846, 5%);
  }

  .modal-close-button {
    float: right;
  }

}

// Hide testpots button where there isn't a testpot
.no-testpot-products{
  display: none;
}

.cms-shop-by-colour {
  h1.page-title {
    margin-bottom: 0;
  }
}

//Search controls
.search_controls {
  input[type="text"] {
    height: 40px;
    margin-bottom: 12px;
  }
  select {
    height: 40px;
  }
}


//Colour palette filter
button.colour_palette {
    border-radius: 3px;
    color: white;
    margin-bottom: 18px;
    height: 64px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;

    &:hover {
      color: white;
      filter:brightness(110%);
      text-decoration: underline;
      background-size: cover;
    }
    &:focus {
      background-size: cover;
      color: white;
    }
    &:active {
      background-size: cover;
      color: white;
    }
}

.colour_palette_row {
    margin-top: 10px;
    margin-bottom: 20px;

    .colour-palette-border {
      border-top: 1px solid #ccc;
      margin: 0 15px 30px;
    }

}


//colour squares
.colour-squares {
  margin-bottom: 24px;

  a {
    color: #333;
    &:hover {
      filter:brightness(110%);
    }
  }
}

#loader {
  margin: auto;
  display: none;
}
