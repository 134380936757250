//
//  Fonts
//  ---------------------------------------------

$font-family__resene-icons: 'resene-icons-font';

@font-face {
    font-family: $font-family__resene-icons;
    src: url('../fonts/fontello/fontello.eot');
    src: url('../fonts/fontello/fontello.eot?#iefix') format('embedded-opentype'),
    url('../fonts/fontello/fontello.woff') format('woff'),
    url('../fonts/fontello/fontello.woff2') format('woff2'),
    url('../fonts/fontello/fontello.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

//
//  Custom icons
//  ---------------------------------------------

$resene-icon-heart-empty: '\e800';
$resene-icon-heart: '\e801';
$resene-icon-location: '\f031';
$resene-icon-ok-circle: '\e807';
$resene-icon-cancel-circle: '\e80b';
$resene-icon-search: '\e806';
$resene-icon-up-dir: '\e803';
$resene-icon-left-dir: '\e804';
$resene-icon-right-dir: '\e805';
$resene-icon-down-dir: '\e802';
$resene-icon-paint-interior: '\e80d';
$resene-icon-paint-exterior: '\e80e';

#html-body {

    // h1 {
    //     @include h1();
    // }



    a:not(.action):not(.data),
    .alink:not(.action):not(.data),
    a.action.remind {
        color: $brand-blue;

        &:visited {
            color: $brand-blue;
        }
    }


    // General buttons
    button.primary,
    button.secondary,
    button.size-s,
    .action.size-s,
    .action.primary.continue {
        @include btn();
    }


    button.primary:not(.btn-afterpay-checkout),
    .btn--primary,
    .action.primary.continue {
        @include btn-primary();
    }

    button.secondary {
        @include btn-secondary();
    }

    .action-select-shipping-item {
        @include btn-grey();
    }



    // Custom buttons
    a.action.create.primary {
        @include btn();
        @include btn-primary();
        padding: 13px 17px;
    }
    a.advanced {
        color: $brand-grey;
    }

}

