#html-body {
    .toolbar.toolbar-products {
        padding: 0px;
        margin-top: 6px;
        .toolbar-amount {
            font-size: 15px;
            float: left;
            padding: 7px 0 0;            
            @media (max-width: 767px) {
                float: left;
                padding: 7px 0 0;
            }
        }
        .toolbar-sorter {
            position: relative;
            top: 0px;
            right: -8px;
            padding: 0px;
            .sorter-label {
                font-size: 15px;
                @media (max-width: 767px) {
                    display: none;
                }
            }
            select {
                appearance: none;
                background-repeat: no-repeat;
                background-position-x: calc(100% - 10px);
                background-position-y: 50%;
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='rgb(0, 0, 0)' viewBox='0 0 24 24' width='18' height='18' aria-hidden='true' focusable='false'><path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m19 9-7 7-7-7'></path></svg>");
                
                font-size: 15px;
                border-radius: 4px;
                border: 1px solid #C2C2C2;
                height: 40px;
                width: 15rem;
                padding: 0rem 1.5rem;
                margin: 0 0 0 1rem;
                &:hover,
                &:focus,
                &:active {
                    outline: none;
                    box-shadow: none;
                }
            }
            a.sorter-action {
                margin-top: 4px;
            }
        }
    }
}
