.ampickupmsi-main-container {
    margin-top: 20px;
    padding: 20px;
    background: #f6f6f6;
}
.ampickupmsi-choose-options {
    font-weight: 400;
    background: transparent;
    padding: 0;
    margin: 0;
}

#check-available-location-button {
    padding: 10px;
    width: 100%;
    background-color: $brand-blue;
    color: #fff;
    border-radius: 4px;
    border: none;
    font-size: 12px;
    font-weight: 600;

    &:after {
        margin-left: 10px;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        speak: none;
        text-align: center;
        font-family: $font-family__resene-icons;
        content: $resene-icon-right-dir;
    }
}

.ampickupmsi-locations-container {
    padding: 20px 5px 0;

    &.-no-expander {
        padding-bottom: 20px;
    }

    .ampickupmsi-title {
        margin: 0 0 15px 15px;
        font-weight: 600;
        font-size: 16px;
    }

    .ampickupmsi-locations {
        overflow: auto;
        height: calc(100vh - 210px);

    }

    .ampickupmsi-location {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #f6f6f6;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        background: #f6f6f6;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

        &:hover,
        &:active,
        &:focus {
            background: lightness(#f6f6f6);
            -webkit-tap-highlight-color: transparent;
            border-color: $brand-green;
        }

        &.-hidden:not(.-allowed) {
            display: none;
        }
    }

    .ampickupmsi-info {
        display: flex;
        box-sizing: border-box;
        width: calc(100% - 120px);
        align-items: center;
        @media (max-width: 767px) {
            width: calc(100% - 100px);
        }
    }
    .ampickupmsi-map {
        margin: 2px 10px 0 0;
        min-width: 20px;
        width: 14px;
        height: 20px;
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNCA3YzAgNS02IDEzLTcgMTNTMCAxMiAwIDdhNyA3IDAgMDExNCAwem0tNyAzYTMgMyAwIDEwMC02IDMgMyAwIDAwMCA2eiIgZmlsbD0iIzAwNkJCNCIvPjwvc3ZnPg==) no-repeat center;
        background-size: contain;
    }

    .ampickupmsi-name {
        display: block;
        font-weight: 600;
        text-decoration: underline;
        @media (max-width: 767px) {
            font-size: 12px;
        }
    }

    .ampickupmsi-address {
        color: #6d6d6d;
        display: none;
    }

    .ampickupmsi-stock {
        width: 120px;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: flex-end;
        font-size: 1.2rem;
        @media (max-width: 767px) {
            width: 100px;
            font-size: 1rem;
        }
    }

    .ampickupmsi-count {
        display: block;
        font-weight: 700;
        padding-right: 5px;
    }

    .ampickupmsi-stock-status {
        color: #6d6d6d;
        text-transform: capitalize;

        &:after {
            margin: 0 0 0 3px;
            vertical-align: top;
            display: inline-block;
            font-weight: normal;
            overflow: hidden;
            speak: none;
            text-align: center;
            font-family: $font-family__resene-icons;
            font-size: inherit;
            content: $resene-icon-ok-circle;
            color: $brand-green;
        }

        &.out-of-stock {
            &:after {
                content: $resene-icon-cancel-circle;
                color: #ff5501;
            }
        }
    }
}

.ampickupmsi-full-list {
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: #006bb4;
    cursor: pointer;
    display: none;

    .ampickupmsi-arrow {
        margin-left: 10px;
        width: 12px;
        height: 7px;
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNi41ODIgNi45NzNsNS45NjEtNS43OTctLjk1Ny0xLjAxMi01LjAwNCA0Ljg2N0wxLjQxNC4wMjdsLS45NTcuOTg1IDUuNzcgNS42MzMuMzU1LjMyOHoiIGZpbGw9IiMwMDZCQjQiLz48L3N2Zz4=) no-repeat center;
        background-size: contain;
    }

    &.-expanded {
        .ampickupmsi-arrow {
            transform: rotate(180deg);
        }
    }

    &.-expanded.-gradient:after {
        position: absolute;
        bottom: 100%;
        display: none;
        width: calc(100% + 5px * 2);
        height: 100px;
        background: linear-gradient(to top, #f6f6f6, rgba(255, 255, 255, 0));
        content: '';
        pointer-events: none;
    }
}
.ampickupmsi-loader-container {
    position: relative;
    min-height: 70px;
}
.ampickupmsi-loader-container.-hidden {
    display: none;
}
.ampickupmsi-loader-container > .loading-mask {
    position: absolute;
}
.ampickupmsi-loader-container > .loading-mask > .loader > img {
    position: absolute;
}
.ampickupmsi-link-wrapper.-hidden {
    display: none;
}

.pdp-store-pickup-locator{
    max-width: 465px;
    left: auto;

    .modal-header {
        background-color: #f6f6f6;
    }

    .modal-title {
        @media (min-width: 768px) {
            font-size: 22px;
        }
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0;
    }

    .modal-content {
        height: calc(100vh - 70px);
    }

    .ampickupmsi-filter-wrapper {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        position: relative;
        background: #f6f6f6;
        margin: 0 -2.6rem;
        padding: 0 2.6rem 2.6rem 2.6rem;

        .top-search-label {
            width: 100%;
            height: 30px;
            font-size: 12px;
        }

        input[type="text"] {
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            padding-right: 40px;

            &:active,
            &:focus {
                outline: none;
                box-shadow: 0 0 6px -2px $brand-green;
            }
        }

        button {
            position: absolute;
            top: 30px;
            right: 2.6rem;
            left: auto;
            background: transparent;
            border: none;
            font-size: 0;
            width: 40px;
            height: 32px;
            padding: 0;
            text-align: center;

            &:before {
                margin: 0;
                vertical-align: top;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
                font-family: $font-family__resene-icons;
                font-size: 18px;
                content: $resene-icon-search;
                line-height: 1;
            }
        }

        .bottom-search-label{
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            align-items: flex-end;
            width: 100%;
            min-height: 30px;
            font-size: 12px;
            font-weight: 600;

            label.no-results {
                margin-top: 10px;
                color: #ff5501;
                font-size: 14px;
            }

            span.search-near-me {
                color: $brand-blue;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .ampickupmsi-locations-container {
        padding: 0;
        background: transparent;

        .ampickupmsi-title {
            display: none;
        }
    }
}

.quick-store-check {
    padding: 20px 0 10px 0;
    border-top: 1px solid lightgrey;

    label {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        font-weight: 700;

        &:before {
            margin-right: 5px;
            vertical-align: top;
            display: inline-block;
            font-weight: normal;
            speak: none;
            text-align: center;
            font-family: $font-family__resene-icons;
            font-size: 18px;
            content: $resene-icon-location;
            color: $brand-blue;
        }
    }
}
