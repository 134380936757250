.mega-menu {
    $underline-gap: 1.7rem;
    $underline-height: 7px;
    $top-offset: -71px;

    position: relative;
    width: 100%;
    margin-top: $top-offset;
    display: none;
    @media (min-width: 768px) {
        display: block;
    }

    .mega-menu-wrapper {
    }

    .first-level {
        cursor: pointer;
        position: relative;
        font-weight: bold;
        font-size: 19px;  
        line-height: 1; 
        color: white !important;
        text-decoration: none;
        padding-left: $underline-gap;
        padding-right: $underline-gap;
        padding-top: 0px;
        padding-bottom: 0px;
        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
        &:first-child {
            // Left adjustment here. Not in .mega-menu-wrapper
            margin-left: 0rem;
            @media (min-width: 1200px) {
                margin-left: 3rem;
            }
        }
        &.has-submenu {
            &:before {
                content: '';
                display: block;            
                background: url('../images/icons/icon-megamenu-dropdownarrow-up-white.svg') no-repeat transparent;
                background-size: contain;
                width: 10px;
                height: 6px;
                position: absolute;
                right: 0px;
                top: 50%;
                transform: translateY(-50%);
                transform-origin: top;
            }
            &.active {
                &:before {
                    rotate: 180deg;
                }
            }
        }
        &.active {
            &:after {
                content: '';
                display: block;
                background: $brand-dark-green 0% 0% no-repeat padding-box;
                position: absolute;
                width: calc(100% - #{$underline-gap} * 2);
                left: calc(0px + #{$underline-gap});
                top: calc(100% + 41px);
                height: $underline-height;
            }
        }

    }

    .mega-submenu {
        display: none;
        background: white;
        position: absolute;
        z-index: 9999;
        border-bottom: 1px solid #C3C3C3;
        box-shadow: -1px 10px 16px -7px rgba(0,0,0,0.27);
        top: calc( #{$top-offset} * -1 );
        width: calc(100vw - 15px);
        padding-top: 38px;
        padding-bottom: 38px;
        &.active {
            display: block;
        }

        .mega_menu_block_shop_by_row,
        .mega_menu_block_tools_and_resources_row {
            max-width: calc(1280px + 40px);
            margin: auto;
            padding-left: 11px; //20px;
            padding-right: 20px;

            .pagebuilder-column-group {
                .pagebuilder-column {
                    @media (max-width: 991px) {
                        width: 25%!important;
                    }
                    h3 {
                        &:first-child {
                            padding-top: 0em;
                            margin-top: 0rem;
                        }
                    }
                    &.column-has-image {
                        @media (max-width: 991px) {
                            display: none !important;
                        }
                    }
                }
            }
        }


        a {
            color: black !important;
            &:hover,
            &:focus,
            &:active {
                color: black !important;
                text-decoration: none;
            }
        }
        h3,
        [data-content-type="text"] {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        h3 {
            color: black;
            font-weight: 700;
            font-size: 20px;
            line-height: 1.5;
            padding-top: 1em;
            padding-bottom: 0.5em;
        }
        [data-content-type="text"] {
            color: black;
            font-size: 18px;
            line-height: 1.2;
            p {
                margin-bottom: 1.5rem;
            }
        }
        figure {
            img {
                width: 100%;
            }
        }


    }

}