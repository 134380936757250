#html-body {

    // Mobile NAV
    .nav-toggle {
        z-index: 999;
        @media (max-width: 767px) {
            top: 32px;
            left: $side-padding-m;
        }
        &:before {
            content: '';
            font-family: inherit;
            background: url('../images/icons/icon-mobile-hamburger.svg') no-repeat no-repeat transparent;
            background-size: contain;
            width: 24px;
            height: 18px;
        }
    }

    // Mobile Menu
    .nav-sections {
        z-index: 999;
    }
  

}