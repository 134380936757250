$mobile-menu-bg: #202226;
$parent-font-size: 19px;
$child-font-size: 18px;

$pad-top: 2.3rem;
$pad-bottom: 2.3rem;
$pad-left: 4rem;
$pad-right: 2.6rem;

$divider-color: #555555;
$divider-color-2: #CBCBCB;

.nav-before-open {
    &.nav-open {
        // MOBILE CLOSE ICON
        .mobile-menu-close-icon {
            @media (max-width: 767px) {
                display: block;
                content: '';
                cursor: pointer;
                position: absolute;
                top: 17px;
                right: 20px;
                z-index: 999999;
                background: url('../images/icons/icon-ionic-md-close.svg') no-repeat transparent;
                background-size: contain;
                width: 15px;
                height: 15px;
                text-indent: -9999px;
            }
        }
        .page-wrapper {
            left: 0 !important;
        }
    }
}
.mobile-menu-close-icon {
    display: none;
}


// MOBILE TABS
.nav-sections {
    background: $mobile-menu-bg;

    .nav-sections-item-title {
        cursor: pointer;
        border: none;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        height: auto;
        max-width: 165px;
        &.active {
            a {
                color: $brand-yellow !important;
            }
        }
        a {
            font-weight: 600;
            color: #A3A3A3 !important;
        }
    }
    .section-item-content {
        padding: 0px;
        margin-top: 50px;

        ul {
            li {
                padding: 0 !important;
            }
        }

        ul.header.links {
            border: none;
            li {
                display: block !important;
                a {
                    border: none;
                    line-height: 1.2;
                    font-size: $parent-font-size;
                    color: white !important;
                    padding-left: $pad-left !important;
                    padding-top: $pad-top;
                    padding-bottom: $pad-bottom;
                    border-bottom: 1px solid $divider-color;
                    &:hover,
                    &:focus,
                    &:active {
                        text-decoration: none;
                    }
                    &.customer-dropdown-menu {
                        pointer-events: none;
                    }
                }
                &.greet.welcome {
                    border-top: none;
                }
                ul.customer-dropdown-menu-links {
                    width: 100% !important;
                    top: 0px !important;
                    padding-top: 0px !important;
                    padding-bottom: 0px !important;
                    background: inherit !important;
                    li {
                        a {
                            padding-top: $pad-top !important;
                            padding-bottom: $pad-bottom !important;
                        }
                    }
                }
            }
        }
        ul.customer-dropdown-menu-links {
            display: block !important;
            position: relative !important;
            li {
                display: block !important;
                a {
                    border: none;
                    line-height: 1.2;
                    font-size: $parent-font-size;
                    color: white !important;
                    padding-left: $pad-left;
                    padding-top: $pad-top;
                    padding-bottom: $pad-bottom;
                    border-bottom: 1px solid $divider-color;
                    &:hover,
                    &:focus,
                    &:active {
                        text-decoration: none;
                    }
                }
            }
        }
    }


}


//
// MOBILE MENU
//
.mobile-menu {

    a {
        line-height: 1.2;
        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
    }

    &.categories {
    
        .mobile-menu-wrapper {
            a.first-level {
                cursor: pointer;
                position: relative;
                display: block;
                color: white !important;
                font-weight: 700;
                font-size: $parent-font-size;
                margin: 0;
                padding-left: $pad-left;
                padding-top: $pad-top;
                padding-bottom: $pad-bottom;
                border-bottom: 1px solid $divider-color;
                &.has-submenu {
                    &.active {
                        &:before {
                            rotate: 0deg;
                        }
                        & + .mobile-menu-submenu {
                            display: block; // unhide
                        }
                    }
                    &:before {
                        content: '';
                        display: block;
                        background: url('../images/icons/icon-feather-chevron-down-orange.svg') no-repeat transparent;
                        background-size: contain;
                        width: 15px;
                        height: 8px;
                        rotate: 180deg;
                        position: absolute;
                        right: $pad-right;
                        top: 50%;
                        transform: translateY(-50%);
                        transform-origin: top;
                    }
                }
            }
            .mobile-menu-submenu {
                display: none; // hide
                background: white;
                .mega_menu_block_shop_by_row,
                .mega_menu_block_tools_and_resources_row {
                    .pagebuilder-column-group {
                        @media (max-width: 767px) {
                            flex-wrap: wrap;
                        }
                        .pagebuilder-column {
                            @media (max-width: 767px) {
                                flex-basis: 100%;
                            }
                            h3 {
                                cursor: pointer;
                                position: relative;
                                font-weight: 700;
                                font-size: $parent-font-size;
                                margin: 0;
                                padding-left: $pad-left;
                                padding-top: $pad-top;
                                padding-bottom: $pad-bottom;
                                border-top: 1px solid $divider-color-2;
                                border-bottom: 1px solid $divider-color-2;
                                margin-top: -1px;
                                &:before {
                                    content: '';
                                    display: block;
                                    background: url('../images/icons/icon-feather-chevron-down.svg') no-repeat transparent;
                                    background-size: contain;
                                    width: 15px;
                                    height: 8px;
                                    rotate: 180deg;
                                    position: absolute;
                                    right: $pad-right;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    transform-origin: top;
                                }
                                &.active {
                                    border-bottom: none;
                                    &:before {
                                        rotate: 0deg;
                                    }
                                }

                            }
                            [data-content-type="text"] {
                                display: none; // hide
                                font-weight: normal;
                                font-size: $child-font-size;
                                &.active {
                                    display: block; // unhide
                                    &.first {
                                        a {
                                            padding-top: 0rem;
                                        }
                                    }
                                    &.last {
                                        a {
                                            padding-bottom: $pad-bottom;
                                        }
                                    }
                                }
                                p {
                                    margin: 0;
                                }
                                a {
                                    display: block;
                                    color: black !important;
                                    margin: 0;
                                    padding-left: $pad-left;
                                    padding-top: $pad-top/2;
                                    padding-bottom: $pad-bottom/2;
                                }                                
                            }
                            &.column-has-image {
                                display: none !important;
                            }
                            figure {
                                @media (max-width: 767px) {
                                    display: none !important;
                                }
                            }
                        }
                    }
                }
            }

        }
    
    }

}