.simpletabs {
	*[role=tablist] {
		box-shadow: inset 0 -1px 0 #aaa;
		padding: 0 0 0 0.5rem;
		> li {
			border: 1px solid transparent;
			border-bottom: 0;
			color: #008;
			cursor: pointer;
			display: inline-block;
			padding: 1rem 2rem;
			margin: 0;
			&.active {
				background: #fff;
				border-color: #aaa;
				border-bottom: 0;
				background: white;
				border-radius: 5px 5px 0 0;
			}
		}
	}
	.tab-content div[role=tabpanel] {
		display: none;
		&.active {
			display: block;
		}
	}
}
