.catalogsearch-result-index,
.amsearch-search-page {

    main#maincontent {
        padding-top: 1.3rem;
    }

    .search_banner {
        border-radius: 8px;
        background: #F0F0F0;
        padding: 2.1rem;
        text-align: center;
        font-size: 14px;
        margin: 5.6rem 0rem 0rem;
        @media (max-width: 767px) {
            margin: 1rem 0rem 0rem;
        }
        a {
            text-decoration: underline;
        }
        p {
            &:last-child {
                margin-bottom: 0rem;
            }
        }
    }    
}
