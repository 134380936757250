#html-body {

  // Margin top allowance for sticky header
  &.cms-noroute-index,
  &.customer-account-login,
  &.customer-account-forgotpassword,
  &.account,
  &.customer-account-logoutsuccess,
  &.customer-account-createpassword,
  &.customer-account-create {
    main.page-main {
      @media (max-width: 767px) {
        margin-top: $header-height-mobile !important;
      }
      @media (min-width: 768px) {
        margin-top: $header-height-desktop !important;
      }
    }
  }



  &.customer-account-login {

    .block-customer-login {
      .fieldset:after {
        text-align: left;
      }
    }

    .actions-toolbar {
      display: flex;
      align-items: center;

      .primary {
        margin-bottom: 0;
        a.action.create.primary {
          @include btn();
          @include btn-primary();
        }
      }
      .secondary {
        a.action.remind {
          margin-top: 0px;
          margin-left: 1.5rem;
        }
      }
    }

  }


  &.account {

    // Layouts
    .nav-sections {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .sidebar.sidebar-main,
    .sidebar.sidebar-additional {
      width: 25%; // 22.3%;
      padding-right: 35px;
      @media only screen and (max-width: 850px) {
        padding-right: 20px;
      }
      @media only screen and (max-width: 767px) {
        padding-right: 0px;
      }
    }
    .columns {
      display: flex;
      flex-wrap: wrap;
      @media only screen and (min-width: 768px) {
        display: block;
      }
    }
    .column.main {
      width: 74%; // 77.7%;
    }

    // Ordering
    .sidebar.sidebar-main {
      @media only screen and (max-width: 767px) {
        order: 0;
      }
    }
    .sidebar.sidebar-additional {
      @media only screen and (max-width: 767px) {
        order: 1;
      }
    }

    #maincontent {
      @media only screen and (max-width: 767px) {
        padding-top: 0;
        margin-top: 0;
      }
      .page.messages {
        margin-bottom: 0;
        div[data-placeholder="messages"] {
          & + div {
            .message.error {
              margin-bottom: 1.5em;
            }
          }
        }
      }
    }

    .page-title-wrapper {
      .page-title {
        margin-bottom: 1em !important;
      }
    }


    // Links general styling
    a.action:not(.advanced) {
      color: $brand-blue;

      a:visited,
      .alink:visited {
        color: $brand-blue;
      }
    }

    a.action.edit,
    a.action.view,
    a.action.delete,
    a.action.back,
    a.action.change-password {
      //text-decoration: underline;
    }

    // Buttons
    button.action.update,
    button.deleteMycolours,
    a.action.back {
      @include btn();
      @include btn-grey();
      display: inline-block;
      min-height: 34px;
      font-size: 15px;
      font-weight: bold;
      padding: 9px 19px;
      text-decoration: none;
    }

    // Reorder button
    a.action.order {
      @include btn();
      @include btn-grey();
      display: inline-block;
      min-height: 34px;
      font-size: 15px;
      font-weight: bold;
      padding: 9px 24px;
      text-decoration: none;
      margin-bottom: 1rem;
      text-align: center;
    }

    // View buttons inside tables
    td[data-th="Actions"] {
      .action.view {
        @include btn();
        @include btn-grey();
        display: inline-block;
        min-height: 34px;
        font-size: 15px;
        font-weight: bold;
        padding: 9px 13px;
        text-decoration: none;
        margin-bottom: 1rem;
        margin-top: 1rem;
        text-align: center;
        span {
          white-space: nowrap;
        }
      }
    }

    // Sidebar NAV
    .sidebar.sidebar-main {

      .block.account-nav {
        margin-bottom: 2.5rem;
        .content {
          padding: 30px 0;
          ul.nav.items {
            li {
              &.current {
                > a,
                > strong {
                  border-color: $brand-blue;
                }
              }
              > a,
              > strong {
                color: $black;
                padding: 5px 2.5rem 5px 2.5rem;
                font-size: 16px;
                @media only screen and (min-width: 767px) and (max-width: 992px) {
                  padding: 5px 2.3rem 5px 2.3rem;
                  font-size: 15px;
                }
              }
              .delimiter {
                display: none;
              }
            }
          }
        }
      }
    }


    // Blocks title & content - General styling
    .block.block-dashboard-info,
    .block.block-dashboard-addresses,
    .block.block-addresses-default,
    .block.block-addresses-list,
    .block.block-order-details-view {
      .block-title {
        border-bottom: 1px solid #707070;
        padding-bottom: 1em;
        margin-bottom: 2em;
        strong {
          font-size: 24px;
          font-weight: normal;
        }
      }
      .block-content {
        .box {
          margin-bottom: 2em;
          .box-title {
            > span {
              font-size: 18px;
              font-weight: bold;
            }
          }
          .box-content {
            p {
              line-height: 1.5;
            }
          }
        }
      }
    }

    // Address Book, Account Info
    &.customer-account-edit,
    &.customer-address-index,
    &.customer-address-form {
      // Headings general styling
      legend {
        width: 100%;
        border-bottom: 1px solid #707070;
        padding-bottom: 1em;
        margin-bottom: 2em;
        span {
          font-size: 24px;
          font-weight: normal;
        }
      }

      .column:not(.sidebar-additional) form .actions-toolbar, .column:not(.sidebar-main) form .actions-toolbar, .fieldset .legend, .login-container .fieldset:after {
        @media only screen and (min-width: 768px) {
          margin-left: auto;
        }
      }
    }



    // Recent Orders list - View All
    .block.block-dashboard-orders {
      .block-title {
        //border-bottom: 1px solid #707070;
        padding-bottom: 1em;
        margin-bottom: 2em;
        strong {
          font-size: 24px;
          font-weight: normal;
        }
      }
      .table-wrapper.orders-recent {
        table.table-order-items.recent {
          .col {
            vertical-align: middle;
            width: auto;
            &:nth-of-type(1) {
              width: 14%;
              padding-left: 0;
              @media only screen and (max-width: 639px) {
                width: 100%;
              }
            }
            &:nth-of-type(5) {
              white-space: nowrap;
              @media only screen and (max-width: 920px) {
                white-space: initial;
              }
            }
            &:last-child {
              width: 30%;
              padding-right: 0;
              @media only screen and (max-width: 639px) {
                width: 100%;
              }
            }

            &.actions {
              .action {
                @media only screen and (min-width: 768px) and (max-width: 850px) {
                  font-size: 13px;
                  padding: 9px 9px;
                  min-width: 92px;
                }
              }
            }
          }

          thead {
            tr {
              th {
                border-color: #707070;
                white-space: nowrap;
                @media only screen and (max-width: 850px) {
                  white-space: initial;
                }
              }
            }
          }
          tbody {
            tr {
              border-bottom: 1px solid $brand-grey;
              td {

              }
            }
          }

        }


      }
    }


    // My Account page
    &.customer-account-index {
      a.action.change-password {
        margin-left: 1rem;
      }
    }

    // Account Information - Edit
    &.customer-account-edit {
      .form-edit-account {
        .field.choice {
          display: inline-block;
          margin-right: 2rem;
          margin-bottom: 1rem;
          &:before {
            width: initial;
          }
        }
      }
      .action.primary {
        padding: 11px 31px;
      }
    }

    // Address Book & Details Form
    &.customer-address-index,
    &.customer-address-form {
    }

    // My Orders list page
    &.sales-order-history {
      .table-wrapper.orders-history {
        table.table-order-items.history {

          .col {
            vertical-align: middle;
            width: auto;
            &:nth-of-type(1) {
              width: 14%;
              padding-left: 0;
              @media only screen and (max-width: 639px) {
                width: 100%;
              }
            }
            &:nth-of-type(4) {
              white-space: nowrap;
              @media only screen and (max-width: 800px) {
                white-space: initial;
              }
            }
            &:last-child {
              padding-right: 0;
            }
          }

          thead {
            tr {
              th {
                border-color: #707070;
                white-space: nowrap;
              }
            }
          }
          tbody {
            tr {
              border-bottom: 1px solid $brand-grey;
              td {

              }
            }
          }

        }
      }
    }







    // Order Details page
    // tabs: ordered, invoices, shipments, refunds
    &.sales-order-view,
    &.sales-order-invoice,
    &.sales-order-shipment,
    &.sales-order-creditmemo
    {

      .page-title-wrapper {
        .page-title {
          display: inline-block;
          padding-bottom: 0.5em;
          margin-bottom: 0 !important;
          margin-right: 2rem;
          @media only screen and (max-width: 480px) {
            width: 100%;
          }
        }
      }

      .order-status {
        position: relative;
        top: -8px;
        border: 1px solid $brand-blue;
        color: $brand-blue;
        font-size: 16px;
        text-transform: uppercase;
        padding: 0.5em 1em;
        margin-bottom: 1rem;
        @media only screen and (max-width: 767px) {
          font-size: 13px;
          top: -5px;
          margin-bottom: 2rem;
        }
        @media only screen and (max-width: 480px) {
          top: 0px;
        }
      }

      .order-date {
        margin-top: 0;
      }

      .order-actions-toolbar {
        margin-top: 1rem;
        margin-bottom: 2.5rem;
        .actions {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .action {
            float: none;
            padding-right: 0rem;
            margin-right: 3rem;
            &.show {
              display: block;
            }

            &.order {
              padding: 9px 24px;
              margin-bottom: 0rem;
            }
            &.print {
              &:before {
                content: '';
                position: relative;
                top: 3px;
                display: inline-block;
                background: url('../images/icons/icon-print.svg') no-repeat transparent;
                background-size: contain;
                width: 16px;
                height: 16px;
              }

              &:hover,
              &:focus,
              &:active {
                text-decoration: none;
                span {
                  text-decoration: underline;
                }
              }

              span {
                display: inline-block;
                line-height: 1;
                padding-left: 0.5rem;
                //text-decoration: underline;
              }
            }
          }
        }
      }


      .gst-number {
        display: none;
        &.show {
          display: block;
        }
      }

      ul.items.order-links { // extra tabs
        position: relative;
        border-bottom: none;
        display: flex;
        border-bottom: none;
        justify-items: center;
        align-items: center;
        text-align: center;
        margin-bottom: 0px;
        &:after {
          z-index: 0;
          position: absolute;
          bottom: 0px;
          content: '';
          width: 100%;
          border-bottom: 1px solid $black;
        }

        li {
          z-index: 2;
          position: relative;
          padding: 1em;
          margin-right: 0px;
          @media only screen and (max-width: 768px) {
            padding: 0.5em;
            margin-right: 5px;
            font-size: 14px;
          }

          &:last-child {
            margin-right: 0px;
          }

          &.current {
            font-weight: 700;
            border: 1px solid $black;
            border-bottom: 1px solid $white;
          }
        }
      }
      .order-details-items {
        margin-top: 1rem;
        border-bottom: none;
        &.invoice,
        &.shipments,
        &.creditmemo {
          .action.print {
            display: none; // Initially hide. Then move using js and show again.
          }
        }

        .order-title {
          width: 100%;
          border-bottom: 1px solid #707070;
          padding-bottom: 1em;
          margin-bottom: 2.5em;
          strong {
            font-size: 24px;
            font-weight: normal;
            margin-top: 1rem;
            margin-bottom: 1rem;
          }
          + p { // GST
            margin-bottom: 0;
          }

          .action.print {
            display: none;
          }
          .action.track {
            padding-left: 2rem;
            @media only screen and (max-width: 639px) {
              display: block;
              padding-left: 0;
              padding-bottom: 1rem;
            }
          }
        }
      }
      .table-wrapper {
        table {
          margin-top: 3.5rem;

          .col {
            //vertical-align: middle;
            width: auto;
            &:nth-of-type(1) {
              width: 30%;
              @media only screen and (max-width: 639px) {
                width: 100%;
              }
            }

            &.price,
            &.qty,
            &.subtotal,
            &.discount,
            &.msrp {
             text-align: right;
            }

            &.price {
             text-align: left;
            }

            &.qty {
              @media only screen and (max-width: 639px) {
                text-align: left;
              }
            }

            .product-item-name {
              margin: 0;
            }
          }

          th,
          td {
            &:nth-of-type(1) {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
            }
          }

          thead {
            @media only screen and (min-width: 640px) {
              border-top: 1px solid #707070;
              border-bottom: 1px solid #707070;
            }

            tr {
              th {
                border-bottom: none;
                font-size: 15px;
                font-weight: bold;
                padding: 1em 10px;
              }
            }

            & + tbody {
              tr {
                border-bottom: 1px solid $brand-grey;
                @media only screen and (max-width: 639px) {
                  &:first-child {
                    border-top: 1px solid $brand-grey;
                  }
                }
                td.col {
                  padding-top: 1em;
                  padding-bottom: 1em;
                  padding-left: 10px;
                  padding-right: 10px;
                  @media only screen and (max-width: 639px) {
                    padding-top: 0;
                    padding-left: 0;
                    padding-right: 0;
                    &:first-child {
                      padding-top: 1em;
                    }
                  }

                  &:first-child {
                    padding-left: 0px;
                  }
                  &:last-child {
                    padding-right: 0px;
                  }
                }
              }
            }
          }
          tfoot {
            // Totals
            // order & invoice slightly different structure
            & + tbody,
            & {
              tr {
                text-align: right;

                &:first-child {
                  th {
                    padding-top: 1em;
                  }
                  td {
                    padding-top: 1em;
                  }
                }
                th {
                  font-weight: 700;
                  text-align: right;
                  padding-top: 0em;
                  padding-bottom: 1em;
                  @media only screen and (max-width: 639px) {
                    display: none;
                  }
                  &:after {
                    @media only screen and (min-width: 640px) {
                      padding-right: 0;
                      //content: ': ';
                      content: '';
                      display: block;
                      color: $black;
                      font-weight: 700;
                    }
                  }
                }
                td {
                  padding-top: 0em;
                  padding-bottom: 1em;
                  @media only screen and (max-width: 639px) {
                    display: block;
                    width: 100%;
                    padding-top: 0em;
                    padding-bottom: 1em;
                  }

                  &:before {
                    @media only screen and (max-width: 639px) {
                      padding-right: 10px;
                      content: attr(data-th) ": ";
                      display: inline-block;
                      color: #111111;
                      font-weight: 700;
                    }
                  }

                }
              }
            }
          }

        }
      }
      .block-order-details-view {
        .actions-toolbar {
          display: block;
          .secondary {
            margin-top: 3rem;
            @media only screen and (min-width: 768px) {
              float: left;
            }

            .action {
              @include btn();
              @include btn-grey();
              display: inline-block;
              min-height: 34px;
              font-size: 15px;
              font-weight: bold;
              padding: 9px 28px;
              text-decoration: none;
              margin-bottom: 1rem;
              margin-top: 1rem;
            }
          }
        }
      }
    }


    // invoices, shipments & creditmemo (not orders)
    &.sales-order-invoice,
    &.sales-order-shipment,
    &.sales-order-creditmemo {

      .actions-toolbar {
        .actions {
          @media only screen and (max-width: 639px) {
            display: block;
          }
          .action {
            @media only screen and (max-width: 639px) {
              margin-bottom: 1rem !important;
            }

            &.print {
              &:not(.show) { // Hide "Print Order", just for invoices, shipments & credit memo
                display: none;
              }
            }
          }
        }
      }

      .order-details-items.invoice,
      .order-details-items.shipments,
      .order-details-items.creditmemo {
        .table-wrapper {
          tbody {
            tr { // tr.order-item-row-* {
              @media only screen and (max-width: 639px) {
                border-bottom: 0px solid $brand-grey;
              }
            }
          }
        }
      }
    }


    // My Wishlist page
    &.wishlist-index-index {
      ol.product-items {
        display: flex;
        flex-wrap: wrap;
        @media only screen and (min-width: 768px) {
          margin-left: -20px;
          margin-right: -20px;
        }
        li.product-item {
          padding-left: 20px;
          padding-right: 20px;
          margin-left: 0px;
          @media only screen and (min-width: 768px) {
            width: calc(50%);
          }
          @media only screen and (min-width: 1024px) {
            width: calc(25%);
          }

          .product-item-info {
            @media only screen and (min-width: 768px) {
                width: auto;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                flex-flow: column;
            }
          }

            .product-item-photo {
                max-width: 100%;
                max-height: 250px;
            }

            .product-item-inner {
                margin-top: auto;
            }

            .action.primary {
                color: $white;
                min-height: 44px;
                line-height: 1;
                cursor: pointer;
                font-size: 15px;
                padding: 13px 17px;

                &:hover,
                &:active {
                    background: #007a38;
                    border: 1px solid #007a38;
                }
            }
        }
      }
      .product-item-actions {
      }
    }

    // My Colours page
    &.mycolours-customer-index {
      .table-mycolours {
        tr {
          border-bottom: 1px solid #ccc;

          td {
            padding: 12px;
          }
        }

        @media (max-width: 767px) {
          tr {
            td {
              display: block;
              padding: 8px 0px;
              width: 290px;
            }

            th {
              display: none;
            }
          }

        }
      }
    }

  }

}


//DIY card benefit modal
.diycardModalLink {
    display: inline-block;
}
#diyCardModalContent {
    text-align: left;
    .modal-close-button {
        float: right;
    }
}

