.decoy-z-index { z-index: 1; }
.decoy-z-index { z-index: 2; }
.decoy-z-index { z-index: 3; }
.decoy-z-index { z-index: 4; }
.decoy-z-index { z-index: 5; }
.decoy-z-index { z-index: 6; }
.decoy-z-index { z-index: 7; }
.decoy-z-index { z-index: 8; }
.remodal-overlay.remodal-is-opened {
	z-index: 9;
}
.remodal-wrapper.remodal-is-opened {
	z-index: 20;
}
button.remodal-close {
    left: auto;
    right: 1rem;
    top: 1rem;
}
