#html-body.cms-index-index {
	main.page-main {
		@media (max-width: 767px) {
			margin-top: calc(#{$header-height-mobile} - 12px);
		}
		@media (min-width: 768px) {
			margin-top: $header-height-desktop;
		}
	}
}
.home-main-tiles {
	margin-bottom: 48px;
}
.homepage_big_box {
	// add alginment, margin, padding, etc
	display: block;
	height: 30rem;
	margin-top: 24px;
	position: relative;
	transition: all 200ms;
	&:hover {
		opacity: 0.5;
	}
	&.shop_by_colour {
		background-image: url(../images/homepage/shop-by-colour.jpg);
		background-position: center;
	}
	&.shop_by_product {
		background-image: url(../images/homepage/shop-by-product.jpg);
		background-position: center;
	}
}

#homepage {
	h1.text-center {
		font-size: 30px;
		font-weight: 400;
		text-align: center;
	}
}

.homepage_small_box {
	// add alginment, margin, padding, etc
	background-repeat: no-repeat;
	background-position-y: center;
	background-size: 800px; // adjusted for breakpoints
	background-position-x: 100%;
	background-color: #009846;
	display: block;
	margin-bottom: 24px;
	padding: 1rem 0;
	position: relative;
	transition: all 200ms;
	&:hover {
		opacity: 0.5;
	}
}
*:nth-child(2n) > .homepage_small_box {
	background-color: #FFC100;
}
*:nth-child(3n) > .homepage_small_box {
	background-color: #F15C22;
}
