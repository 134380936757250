@mixin h1 {
    font-size: 26px;
    font-weight: normal;
    line-height: 1.3;
}





@mixin btn {
    min-height: 44px; //52px;
    line-height: 1;
}
@mixin btn-primary {
    cursor: pointer;
    box-shadow: none;
    background: $brand-dark-green;
    border: 1px solid $brand-dark-green;
    border-radius: $btn-border-radius;
    color: $white;
    font-family: $font-primary;
    font-weight: $font-bold;

    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
        color: $white;
        // https://maketintsandshades.com/#009846
        background: #007a38; // 20% darker
        border: 1px solid #007a38; // 20% darker
    }
}
@mixin btn-secondary {
    cursor: pointer;
    box-shadow: none;
    background: $brand-blue;
    border: 1px solid $brand-blue;
    border-radius: $btn-border-radius;
    color: $white;
    font-family: $font-primary;
    font-weight: $font-bold;

    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
        color: $white;
        // https://maketintsandshades.com/#004FAF
        background: #003f8c; // 20% darker
        border: 1px solid #003f8c; // 20% darker
    }
}
@mixin btn-grey {
    cursor: pointer;
    box-shadow: none;
    background: $brand-grey;
    border: 1px solid $brand-grey;
    border-radius: $btn-border-radius;
    color: $black;
    font-family: $font-primary;
    font-weight: $font-bold;

    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
        color: $black;
        background: $brand-grey;
        border: 1px solid $brand-grey;
    }
}


@mixin select {
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1.3em;
}
