// Font
body,
button, .cart.table-wrapper .actions-toolbar > .action, .action-gift,
select,
input[type="text"], input[type="password"], input[type="url"], input[type="tel"], input[type="search"], input[type="number"], input[type="datetime"], input[type="email"] {
    font-family: "Lexend", sans-serif;
}

.page-wrapper {
    overflow-x: hidden;
}

// Layouts
.header.content,
.panel.wrapper .panel.header {
    padding-left: $side-padding-m;
    padding-right: $side-padding-m;
    @media (min-width: 768px) {
        padding-left: $side-padding-t;
        padding-right: $side-padding-t;
    }
}
.page-main {
    max-width: calc(1280px + 40px);
    padding-top: 3rem;
    padding-left: $side-padding-m;
    padding-right: $side-padding-m;
    @media (min-width: 768px) {
        padding-left: $side-padding-t;
        padding-right: $side-padding-t;
    }
}

#main-content {
    padding-top: 45px;
}
h1.page-title {
    font-weight: 400;
}


.primary .action.primary,
button.action.primary {
    @include btn();
    @include btn-primary();
    font-size: 1.6rem;
    padding: 13px 17px;
}


.field-error,
div.mage-error {
    margin-top: 7px;
    color: #e02b27;
    font-size: 1.2rem;
}

.form.search.advanced .tax_class_id {
    display: none;
}

#co-shipping-method-form fieldset.fieldset.rate {
    max-height: 300px;
    overflow-y: auto;
    margin-left: 0;
    padding-left: 5px;
}

// Add the asterisk here
.totals.levy th:after {
    content: " *";
}

#paintwise-disclaimer-cart {
    display: inline-block;
    margin-top: 2rem;

    @media (min-width: 768px) {
        width: 73%;
    }
}

.paintwise-disclaimer {
    margin-top: 2rem;
}

@media (min-width: 768px) {
    .form-create-account {
        width: 80% !important;

        .fieldset {
            .legend {
                margin-left: 0;
            }
            .label {
                float: none !important;
                text-align: left !important;
            }
            .control {
                float: none !important;
            }
        }
        #diy_terms_conditions  {
            .control {
                margin-top: 14px;
                label.required {
                    font-weight: bold;
                }
            }
            .disclaimer {
                margin-bottom: 30px;
            }
        }
        .actions-toolbar {
            margin-left: 0 !important;
            .secondary {
                float: left;
                margin-left: 24px;
            }
        }
    }
}

// Login page
.specifier-toolbar {
    margin-top: 30px;
}

// Remove cart product "Edit" buttons for now
.cart.table-wrapper .actions-toolbar > .action.action-edit,
.minicart-items .action.edit {
    display: none !important;
}


// Sidebar Addtional - e.g. Recently Ordered
.sidebar.sidebar-additional {
    margin-top: 0;

    .block.block-reorder {
        display: none;
        border: 1px solid $brand-grey;
        padding: 5px 2.8rem 5px 2.8rem;

        .block-title {
            margin-top: 2em;
            margin-bottom: 2em;
            strong {
                font-size: 22px;
                font-weight: normal;
            }
        }

        ol {
            li.product-item {
                .product-item-name {
                    min-height: initial;
                }
                .field.item.choice {
                    margin-right: 0.4em;
                }
                a {
                    color: $black !important;
                    font-size: 14px;
                }
            }
        }

        .actions-toolbar {
            display: flex;
            flex-wrap: wrap;
            max-width: none;
            margin-top: 4rem;
            .primary {
                margin-right: 1.5rem;
                margin-bottom: 1rem;
                .action.tocart {
                    min-height: 34px !important;
                    padding: 8px 16px !important;
                    font-size: 14px !important;
                    font-weight: bold;
                    margin: 0;

                    span {
                        white-space: nowrap;
                    }
                }
            }
            .secondary {
                align-self: center;
                margin-bottom: 1rem;
                .action.view {
                    margin-top: 0;
                    span {
                        white-space: nowrap;
                    }
                }
            }
        }

    }
}