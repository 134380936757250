// sass-lint:disable no-important, no-ids, no-vendor-prefixes
#html-body {
  
    // Margin top allowance for sticky header
    &.checkout-index-index,
    &.checkout-onepage-success {
        main.page-main {
            @media (max-width: 767px) {
              margin-top: $header-height-mobile !important;
            }
            @media (min-width: 768px) {
              margin-top: $header-height-desktop !important;
            }
        }
        #recaptcha-checkout-place-order-wrapper {
            margin: 40px 0 20px 0;
        }
    }


    &.checkout-cart-index {
        .cart-summary {
            .ampickup-store-container {
                .ampickup-field.-date {
                    p {
                        white-space: break-spaces !important;
                    }
                }
            }
        }
    }

    &.checkout-index-index {

        #checkout {
            li#opc-shipping_method {
                > div.checkout-shipping-method {
                    > .step-title {
                        display: none; // Hide "Shipping Methods" header
                    }
                }
            }
        }

        .action-update {
            @include btn();
            @include btn-secondary();
            display: inline-block;
            font-size: 1.6rem;
            padding: 13px 50px;
        }
        .action-edit-address {
            @include btn();
            @include btn-secondary();
            display: inline-block;
            font-size: 15px;
            min-height: 37px;
            padding: 10px 19px;
            margin-top: 1rem;
        }
        .action-cancel {
            @media only screen and (min-width: 768px) {
                margin-top: 0px;
                margin-left: 20px;
            }
        }
        .ampickup-choosemap {
            @include btn();
            @include btn-secondary();
            display: inline-block;
            font-size: 13px;
            min-height: 38px;
            padding: 11px 17px;
            @media only screen and (max-width: 767px) {
                width: 100%;
                text-align: center;
                font-size: 1.6rem;
                min-height: 45px;
                padding: 13px 17px;
            }
        }
        .action.primary.checkout {
            min-height: 48px;
            padding: 13px 60px;
        }

        // Select dropdowns
        select {
            @include select();
        }

        // Checkboxes/radio
        [type="checkbox"],
        [type="radio"] {
            position: relative;
            top: 1px;
        }

        // Fields
        .field {
            &[name$="prefix"] {
                display: none; // hide prefix
            }
            &[name$="street.0"] {
                span {
                    display: none; // hide street address line 1 label
                }
            }
        }

        // Tool tips
        .field-tooltip-content {
            &:before,
            &:after {
                margin: 0px;
            }
            &:before {
                left: -20px;
            }
            &:after {
                left: -19px;
            }

            @media only screen and (max-width: 767px) {
                left: auto;
                top: calc(100% + 7px);
                right: 0px;

                &:before,
                &:after {
                    right: 0px;
                    left: auto;
                    transform: rotate(90deg);
                }
                &:before {
                    top: -21px;
                }
                &:after {
                    top: -20px;
                }
            }

        }


        // Modals
        .modal-custom {

            &.opc-sidebar.opc-summary-wrapper {
                left: 4.0rem;
                .modal-inner-wrap {
                    background-color: white;

                    .action-close {
                        @media only screen and (max-width: 767px) {
                            margin: 0px;
                            top: 15px;
                            right: 8px;

                            &:hover {
                                &:before {
                                    color: #888888;
                                }
                            }

                            &:before {
                                font-weight: bold;
                                color: #888888;
                            }
                        }
                    }
                }
            }

        }
        .modal-popup {
            .modal-footer {
                margin-left: 0px;
                .action.secondary {
                    background: initial;
                    border: initial;
                    border-radius: initial;
                    font-weight: initial;
                    color: $brand-blue;

                    &:hover,
                    &:focus,
                    &:active {
                        color: $brand-blue;
                        text-decoration: underline;
                    }
                }
            }

            #opc-new-shipping-address {
                #shipping-save-in-address-book {
                    display: none;
                }
                label[for="shipping-save-in-address-book"] {
                    display: none;
                }
            }
        }


        .block-search .label {
            @media only screen and (max-width: 767px) {
                top: 15px;
                right: 20px;
            }
        }

        .page-header {
            border: 0;
            margin-bottom: 20px;
        }

        header {
            .header.content {
                .logo {
                    @media only screen and (max-width: 767px) {
                        margin: 0px;
                        left: calc(50%);
                        translate: -50%;
                    }
                }
            }
        }


        h5.heading-delivery-address {
            font-size: 18px;
            font-weight: bold;
            line-height: 1.3;
            margin-top: 3rem;
            @media only screen and (max-width: 767px) {
                font-size: 18px;
                font-weight: normal;
                border-bottom: 1px solid $border-line-grey;
                padding-bottom: 10px;
                margin-bottom: 3rem;
            }
        }

        #tooltip-label,
        .field-tooltip .label {
            display: none;
        }

        .column:not(.sidebar-additional) {
            form {
                .actions-toolbar {
                    margin-left: 0;
                }
            }
        }
        .column:not(.sidebar-main) {
            form {
                .actions-toolbar {
                    margin-left: 0;
                }
            }
        }

        ul.opc-progress-bar {
            margin: 0 0 -10px;
            @media only screen and (max-width: 767px) {
                margin: 0 0 10px;
            }
            li.opc-progress-bar-item {
                &._active {
                    &:before {
                        background: $brand-blue;
                    }

                    span {
                        &:before {
                            background: $brand-blue;
                        }
                    }
                }
            }
        }


        .authentication-wrapper {
            display: none;
        }
        .opc-estimated-wrapper {
            border-bottom: none;
            padding-inline: 0;
            .estimated-block {
                .estimated-label {
                    display: block;
                }
                .estimated-price {
                    display: inline-block;
                }
            }
        }
        .minicart-wrapper {
            margin-top: 0;
            .action.showcart {
                border: none;
                &:before {
                    content: 'Summary';
                    font-family: 'Open Sans';
                    font-size: 15px !important;
                    font-weight: 700 !important;
                    color: #1E2226 !important;
                    border: none !important;
                    margin-right: 1rem !important;
                    line-height: 2.2;
                }
                .counter.qty {
                    background: $brand-green;
                    margin: 4px 0 0;
                    line-height: 24px;
                }
            }
        }
        #customer-email-fieldset {
            .actions-toolbar {
                @media only screen and (min-width: 768px) {
                    width: 100%;
                    margin-left: 25.8%;
                }
                .primary {
                }
                .secondary {

                    @media only screen and (min-width: 768px) {
                        float: left;
                        padding-left: 3rem;

                        .action.remind {
                            margin-top: 12px;
                        }
                    }
                }
            }
        }
        .amlocator-info-popup {
            .amlocator-name {
                .amlocator-title {
                    color: $brand-blue;
                    font-weight: 600;
                }
            }
        }
        .checkout-billing-address {
            margin-top: 2rem;
            margin-bottom: 3rem;

            &:before {
                display: inline-block;
                content: 'Enter your billing address';
                font-size: 18px;
                font-weight: bold;
                padding-bottom: 1rem;
                margin-bottom: 1rem;
                @media only screen and (max-width: 767px) {
                    width: 100%;
                    //border-bottom: 1px solid $border-line-grey;
                }
            }

            .billing-address-details {
                padding-left: 0px;

                a {
                    color: $black;
                }
            }

            .billing-address-form {
                max-width: none;
                @media only screen and (min-width: 768px) {
                    max-width: 500px;
                }
            }
            .actions-toolbar {
                @media only screen and (max-width: 767px) {
                    margin-top: 3rem;
                }
                .primary {
                    @media only screen and (min-width: 768px) {
                        display: flex;
                    }
                }
            }
        }
        .opc-wrapper {
            .step-content {
                margin: 0 0 3.5rem;
            }
            .actions-toolbar {
                max-width: none;
                @media only screen and (min-width: 768px) {
                    .primary {
                        float: left;
                    }
                }
            }
            .form-login {
                margin-bottom: 32px;
                @media only screen and (max-width: 767px) {
                    border-bottom: none;
                }

                .note::before {
                    display: none;
                }
                .actions-toolbar {
                    @media only screen and (min-width: 768px) {
                        float: left;
                        margin-left: 1rem;
                    }
                    > .primary {
                        @media only screen and (min-width: 768px) {
                            float: left;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }


        li#opc-shipping_method {

            .step-title,
            h4 {
                @include h1();
                border-bottom: 1px solid $border-line-grey;
                padding-bottom: 14px;
                margin-bottom: 3rem;
                margin-top: 4rem;
                @media only screen and (max-width: 767px) {
                    font-size: 18px;
                }
            }

            #checkout-step-shipping_method {
                .table-checkout-shipping-method {
                    background: $white;
                    margin-bottom: 2rem;

                    @media only screen and (min-width: 768px) {
                        width: -webkit-fill-available;
                    }
                    .row {
                        margin: 0;
                    }
                    .col-method {
                        flex-grow: inherit;
                        white-space: nowrap;
                    }
                    .col-carrier {
                        text-align: center;
                    }
                    tbody {
                        display: flex;
                        flex-wrap: wrap;
                        .row {
                            cursor: pointer;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            padding: 0.5rem 2rem;
                            border: 2px solid #cccccc;
                            margin-right: 2.5rem;
                            margin-bottom: 2rem;
                            min-width: 280px;
                            @media only screen and (max-width: 767px) {
                                width: 100%;
                                margin-right: 0;
                            }

                            &.is-selected {
                                border: 2px solid $brand-blue;
                            }

                            &.row-error {
                                padding: 10px;
                            }
                        }
                        .col {
                            border-top: none;
                            text-align: left;
                            width: auto;
                            flex-grow: 0;
                            white-space: initial;

                            &.col-method:nth-of-type(1) { // radio button selection
                                order: 1;
                            }

                            &.col-price { // price
                                order: 3;
                                font-size: 14px;
                                font-weight: bold;
                                padding-left: 0rem;
                                min-width: 8rem;

                                .price {
                                    display: inline-block;
                                }

                                > .price {
                                    padding-left: 0.5em;
                                    > .price {
                                        &:before {
                                            display: inline-block;
                                            content: '-';
                                            padding-right: 0.5em;
                                        }
                                    }
                                }
                            }

                            &.col-method:not(:nth-child(1)) { // method
                                display: none;
                                order: 4;
                                flex: 0 1 30%;
                            }

                            &.col-carrier { // carrier
                                order: 2;
                                white-space: nowrap;
                                font-size: 14px;
                                font-weight: bold;
                                padding-right: 0rem;
                                @media only screen and (max-width: 468px) {
                                    //white-space: initial;
                                }
                            }

                            &.col-error {
                                display: flex;
                                flex: 100%;
                                padding: 0;

                                .message.error {
                                    margin: 0;
                                }
                            }
                        }
                    }
                }

                form.ampickup-store-container {
                    .ampickup-field {
                        .label {
                            width: 100%;
                            float: none;
                            padding-bottom: 6px;
                            text-align: left;
                        }
                    }
                    .ampickup-details-container {
                        a {
                            color: $black;
                        }
                    }
                }
            }
        }
        li#shipping {
            #checkout-step-shipping {
                margin-bottom: 0;

                form.form-shipping-address {
                    max-width: none;
                    @media only screen and (min-width: 768px) {
                        max-width: 585px;
                    }

                }
                #shipping-new-address-form {
                    .field {
                        &:not(.choice) {
                            > .label {
                                @media only screen and (min-width: 768px) {
                                    width: 22.0%;
                                }
                            }
                            > .control {
                                @media only screen and (min-width: 768px) {
                                    width: 78.0%;
                                }

                                .control {
                                    width: initial;
                                }
                            }
                        }
                    }
                }

                // needed?
                .field.addresses {
                    &:before {
                        display: inline-block;
                        content: 'Select your delivery address';
                        font-size: 18px;
                        font-weight: bold;
                        padding-bottom: 3rem;
                    }
                    .shipping-address-item {
                        &.selected-item {
                            border-color: $brand-blue;

                            &:after {
                                background: $brand-blue;
                            }
                        }
                    }
                }

                .new-address-popup {
                    button.action {
                        @include btn-secondary();

                        &:before {
                            display: inline-block;
                            content: 'Add new address';
                        }
                        span {
                            display: none;
                            &:before {
                                display: none;
                            }
                        }
                    }
                }
            }
            .sparsh-order-comments {
                margin-bottom: 20px;
                label {
                    span {
                        @include h1();
                        display: inline-block;
                        width: 100%;
                        border-bottom: 1px solid $border-line-grey;
                        padding-bottom: 14px;
                        margin-top: 30px;
                        margin-bottom: 3rem;
                        @media only screen and (max-width: 767px) {
                            font-size: 18px;
                        }
                    }
                }
            }
            #co-shipping-method-form-v2 {
                .actions-toolbar {
                    @media only screen and (min-width: 768px) {
                        display: flex;
                        justify-content: flex-start;
                    }
                    button.action {
                        padding: 14px 38px;
                    }
                }
            }
        }
        li#payment {

            #co-payment-form {
                display: flex;

                > fieldset {
                    display: flex;
                    flex-direction: column;
                    min-width: 100%;

                    > .legend,
                    hr {
                        display: none;
                    }
                }
            }

            .items.payment-methods {
                @media only screen and (max-width: 767px) {
                    margin-inline: 0;
                }

                // Payment Method heading
                .step-title {
                    @include h1();
                    border-bottom: 1px solid $border-line-grey;
                    padding-bottom: 14px;
                    //margin-bottom: 1rem;
                    @media only screen and (max-width: 767px) {
                        font-size: 24px;
                    }
                }
                .payment-method {
                    .payment-method-title {
                        padding: 3rem 0;

                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        > .label,
                        > .cc-images > img,
                        > input[type='radio'] {
                            margin-right: 1rem;
                        }
                    }

                    .payment-method-content {
                        padding-inline: 0;
                    }

                    &.payment-method-braintree .payment-method-title {

                    }

                }

                .cc-images {
                    position: relative;
                    .credit-card-types.braintree-credit-card-types {
                        position: absolute;
                        top: -23px;
                        left: 10px;
                        width: 300px;
                        display: block !important;
                        .item {
                            img {
                                filter: initial;
                            }
                        }
                    }
                }
                .credit-card-types.braintree-credit-card-types  {
                    display: none;
                    &.show {
                        display: block;
                        img {
                            filter: none;
                        }
                    }
                }

                #co-transparent-form-braintree {
                    @media only screen and (min-width: 768px) {
                        max-width: 750px;
                    }
                    &:before {
                        display: inline-block;
                        content: 'Enter your credit card details';
                        font-size: 18px;
                        font-weight: bold;
                        color: $black;
                        margin-top: 1rem;
                        padding-bottom: 1rem;
                        @media only screen and (max-width: 767px) {
                            width: 100%;
                            //border-bottom: 1px solid $border-line-grey;
                        }
                    }
                    .braintree-card-control {
                        img {
                            display: none;
                        }
                    }
                    .field {
                        &.cvv {
                            .field-tooltip {
                                left: 6rem;
                            }
                            .field-tooltip-content {
                                &:before,
                                &:after {
                                    left: -20px;
                                    @media only screen and (max-width: 767px) {
                                        top: 33px;
                                    }
                                }
                                &:before {
                                    left: -21px;
                                    border-right-color: #999;
                                }
                            }
                        }
                    }
                }
            }

            .carbonclick-option {
                order: 10;
                .btn {
                    button.primary {
                        background-color: $white !important;
                        border: 2px solid $brand-green;
                        span {
                            color: $black;
                        }

                        &:hover,
                        &:focus,
                        &:active {

                            &:before {
                                background-color: $brand-green !important;
                            }

                            span {
                                color: $white;
                            }
                        }
                    }
                }
            }

            // Apply Discount Code
            .discount-code {
                margin-top: 2rem;
                margin-bottom: 3rem;
                margin-inline: 0;

                &._active {
                    #block-discount-heading {
                        &:after {
                            content: '\e622'; // down arrow
                        }
                    }
                }

                > .payment-option-title {
                    padding-inline: 0;
                    border: none;
                }
                > .payment-option-content {
                    padding: 0 0rem 3rem;
                    @media only screen and (max-width: 767px) {
                        padding: 0 0rem 0rem;
                    }
                }

                #block-discount-heading {
                    font-size: 14px;
                    font-weight: bold;
                    color: $black;

                    &:after {
                        content: '\e621'; // up arrow
                        font-weight: bold;
                        color: $black;
                        margin-top: -3px;
                    }
                }
            }
            .form-discount {
                @media only screen and (max-width: 767px) {
                    max-width: none;
                }

                button.action-apply {
                    @include btn();
                    @include btn-grey();
                    display: inline-block;
                    min-height: 37px;
                    font-size: 15px;
                    font-weight: bold;
                    padding: 10px 19px;
                    text-decoration: none;
                }
            }
        }



        .product-image-wrapper {
            height: 100%;
        }
        .product-image-wrapper img {
            height: 100%;
            width:  auto;
            max-width: 100%;
        }
    }

    // Checkout sidebar
    .opc-block-summary {
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 25px;
        background: white;
        @media only screen and (min-width: 768px) {
            border: 1px solid $border-line-black;
        }

        > .title {
            display: block;
            font-weight: bold;
            line-height: 1.1;
            font-size: 1.9rem;
            padding-top: 21px;
            padding-bottom: 21px;
            padding-left: 27px;
            padding-right: 27px;
            background: $black;
            color: $white;
        }

        table.table-totals {
            width: calc(100% - 30px - 30px);
            margin-left: 30px;
            margin-top: 30px;
            tbody {
                tr {
                    .value {
                        color: $black;
                    }
                }
            }
        }
        .block.items-in-cart {
            padding-left: 30px;
            padding-right: 30px;

            > .title {
                padding: 10px 40px 30px 0;
                margin-right: 0px;
                &:after {
                    content: '\e621'; // up arrow
                    color: white;
                    text-align: right;
                    width: auto;
                    font-size: 38px;
                    font-weight: bold;
                    margin: 0px;
                    right: 10px;
                    top: -38px;
                    @media only screen and (min-width: 768px) {
                        right: -10px;
                    }
                }
            }

            &.active > {
                .title {
                    &:after {
                        content: '\e622'; // down arrow
                    }
                }
            }

            .minicart-items-wrapper {
                padding: 15px 0 15px 0;

                ol.minicart-items {
                    li.product-item {
                        .product-image-container {
                            width: 70px !important;
                            height: auto !important;
                        }
                    }
                }
            }

        }

        li.product-item {
            .product-item-name {
                font-weight: bold;
                font-size: 16px;
                margin-bottom: 0;
                min-height: initial;
            }
            .details-qty {
                font-size: 16px;

                .stock {
                    margin-left: 15px;
                    color: #e02b27;
                }
            }
            .price {
                font-size: 1.4rem;
            }
            .product.options {
                width: initial;
                margin: 0px;
                span[data-role="title"] {
                    font-size: 16px;

                    &:after {
                        font-weight: bold;
                    }
                }
                div[data-role="content"] {
                    padding-top: 6px;
                }
            }
        }


        .table-totals .mark strong {
            font-weight: bold;
        }

        .tr.grand.totals.incl {
            border-top: 1px solid;
        }

        .tr.grand.totals.excl .mark {
            padding-bottom: 20px;
        }

        .actions-toolbar {
            .action.viewcart {
                color: $brand-blue;
            }
        }
    }

    // Shipping information block
    // Ship To:
    // Shipping Method:
    .opc-block-shipping-information {
        padding-top: 3rem;

        .shipping-information {

            .ship-via,
            .ship-to {
                .shipping-information-title {
                    > span {
                        &:after {
                            content: ':';
                            display: inline-block;
                        }
                    }
                }
                .shipping-information-content {
                    a {
                        color: $black;
                    }
                }
            }


            .ship-via {

            }
            .shipping-information-title {
                font-size: 1.8rem;
                font-weight: bold;
                padding-bottom: 4px;
                margin-bottom: 10px;
                .action-edit {
                    position: absolute;
                    top: -2px;
                    right: 0px;
                    &:before {
                        content: 'Edit';
                        color: $brand-blue;
                        font-family: 'Open Sans';
                        font-size: 14px;
                        line-height: 1;
                    }

                    &:hover,
                    &:focus,
                    &:active {
                        &:before {
                            text-decoration: underline;
                        }
                    }
                }
            }
            .ship-to {
                margin-bottom: 6rem;
            }
            .shipping-information-content {

            }

        }
    }


    &.checkout-index-index {

        .checkout-agreements { // t&cs
            @media only screen and (min-width: 768px) {
                //padding-left: 25.8%;
            }

            .choice:before {
                display: none;
            }
            .label {
                padding-right: 0 !important;

                &:after {
                    position: relative !important;
                    top: -3px !important;
                    margin-left: 0 !important;
                }

                button {
                    text-align: left;
                }
            }
            div.mage-error {
                display: inline-block;
                margin-left: 4px !important;
            }
        }

    }

    &.checkout-onepage-success {

        #maincontent {
            text-align: center;

            .page-title {
                margin-top: 0.5em !important;
                margin-bottom: 1em !important;
            }
            .order-number-description {
                font-weight: 300;
                margin-top: 0;
                span {
                    font-weight: 700;
                }
            }
            .sparsh-order-comments {
                display: none;
                margin-bottom: 3em;
            }
            .order-confirmation-details {
                margin-bottom: 0em;
            }
            .actions-toolbar {
                margin-top: 3em;
                margin-bottom: 3em;

                .primary {
                    float: none;
                    .action.primary.continue {
                        @include btn();
                        @include btn-primary();
                        display: inline-block;
                        min-height: 48px;
                        font-size: 15px;
                        font-weight: bold;
                        padding: 15px 38px;
                        text-decoration: none;
                    }
                }
            }
            .action.print {
                color: $brand-blue;
            }

            #registration {
                margin-inline: auto;
                max-width: 800px;
                padding: 2rem 3rem;
                border: 1px solid #cccccc;
                border-radius: 8px;

                br {
                    display: none;
                }
                > div {
                    .messages {
                    }
                    &:not(.messages) {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;
                        p {
                            &:nth-of-type(1) {
                                margin-top: 2rem;
                                margin-bottom: 2rem;
                                padding-right: 5rem;
                                @media only screen and (max-width: 767px) {
                                    padding-right: 0;
                                }
                            }
                            &:nth-of-type(2) { // hide email address
                                display: none;
                            }
                        }
                    }
                }

                .action.primary {
                    @include btn();
                    @include btn-secondary();
                    display: inline-block;
                    min-height: 48px;
                    font-size: 15px;
                    font-weight: bold;
                    padding: 15px 38px;
                    text-decoration: none;
                    @media only screen and (max-width: 767px) {
                        width: 100%;
                        margin-bottom: 2rem;
                    }
                }
            }

        }
    }
}

@media only screen and (max-width: 767px) {
    .checkout-cart-index {
        .cart {
            &.table-wrapper {
                .col {
                    &.qty,
                    &.price,
                    &.subtotal,
                    &.msrp {
                        width: 33.33%;
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                }

                .control.qty {
                    .mage-error {
                        white-space: break-spaces;
                    }
                }
            }
        }
    }
}
