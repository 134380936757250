// When search input is shown + search tinted background overlay
body.-amsearch-overlay-opened {
    .amsearch-form-block {
        z-index: 98;
    }
    .amsearch-overlay-block {
        display: block !important;
    }

    // Tablet
    @media (min-width: 768px) and (max-width: $tablet-breakpoint) {
        header.page-header {
            height: $header-height-tablet !important;
        }
        .header.content:has(.amsearch-wrapper-block.is-shown) {
            & + .mega-menu {
                margin-top: -117px !important;

                .mega-submenu {
                    margin-top: 46px;
                }
            }
        }

        #breadcrumbs {
            margin-top: $header-height-tablet !important;
        }

        // Home page - has no breadcrumbs
        &.cms-index-index {
            main.page-main {
                margin-top: $header-height-tablet !important;
            }
        }        
    }

    // Mobile
    @media (max-width: 767px) {
        $search-input-open-heaight-mobile: 151px;

        header.page-header {
            height: $search-input-open-heaight-mobile !important;
        }

        #breadcrumbs {
            margin-top: $search-input-open-heaight-mobile !important;
        }

        // Home page - has no breadcrumbs
        &.cms-index-index {
            main.page-main {
                margin-top: $search-input-open-heaight-mobile !important;
            }
        }
    }
    
}


#mobile-search-icon {
    display: none;
    @media (max-width: $tablet-breakpoint) {
        display: block;
        position: absolute;
        background: url('../images/icons/icon-ionic-ios-search-grey.svg') no-repeat transparent;
        background-size: contain;
        width: 28px;
        height: 28px;
        right: 69px;
        top: 39px;
        margin-top: 1px;
        z-index: 99999;
    }  
    @media (max-width: 767px) {
        display: block;
        position: absolute;
        background: url('../images/icons/icon-ionic-ios-search-grey.svg') no-repeat transparent;
        background-size: contain;
        width: 20px;
        height: 20px;
        right: 54px;
        top: 30px;
        margin-top: 1px;
        z-index: 900;
    }
}
.amsearch-wrapper-block {
    $opened-width: 358px; 

    float: right;
    position: relative;
    top: -8px;
    right: -17px;
    max-width: none;
    z-index: 100;
    @media (max-width: $tablet-breakpoint) {
        display: none;
        &.is-shown {
            display: block;
            max-width: none;
            position: relative;
            top: -17px;
            right: -42px;
            width: calc(100vw - 52px);
            transition: none;
        }
    }
    @media (max-width: 767px) {
        float: none;
        max-width: 100%;  
        padding-bottom: 18px;
        right: 0px; 
        display: none;
        &.is-shown {
            display: block;
            top: auto;
            right: auto;
            width: initial;
        }
    }
    @media (min-width: 768px) {
        z-index: 10000;
        position: absolute;
        top: 33px;
        right: 61px;
        max-width: 47px;
        transition: max-width 0.3s;
    }
    @media (min-width: ($tablet-breakpoint + 1)) {
        right: 69px;
        max-width: $opened-width;
    }
    &.is-focused {
        @media (min-width: ($tablet-breakpoint + 1)) {
            max-width: $opened-width;
        }
    }
    .amsearch-form-block {
        width: 100% !important;

        .amsearch-input-wrapper {
            &.-typed {
                .amsearch-result-section {
                    border: 1px solid #C3C3C3;
                }
                .amsearch-button {
                    &.-loupe {
                        display: none;
                    }
                }
            }
            input {
                border: none;
                padding: 2.3rem 2.3rem;
                border-radius: 25px;
                line-height: 3;
            }
            .amsearch-button {
                &.-close {
                    right: 7px;
                    filter: invert(1) brightness(0);
                }
                &.-loupe {
                    background: url('../images/icons/icon-ionic-ios-search.svg') no-repeat transparent;
                    background-size: contain;
                    width: 19px;
                    height: 19px;
                    opacity: 1;
                    top: 1px;
                    &:hover,
                    &:focus,
                    &:active {
                        filter: invert(1) brightness(0);
                    }
                    &.-disabled {
                        left: auto;
                        right: 13px;
                    }
                    &:not(.-disabled) {
                        left: 14px;
                        right: auto;
                    }
                }
            }
            
        }
        .amsearch-result-section {
            margin-top: 8px;
            border-radius: 10px;
            max-height: 53vh;
            max-width: 1230px;
            width: calc(100vw - 104px);
            transition: 0s;
            border: none;
            @media (max-width: $tablet-breakpoint) {
                width: 100%;
            }
            .amsearch-highlight {
                color: #009846;
            }

            // Products
            .amsearch-products-section {
                order: -1;
                border-top: none;

                ul.amsearch-product-list {
                    li.amsearch-item {
                        max-width: none;
                        width: 25%;
                        @media (max-width: 991px) {
                            width: 25%;
                        }
                        @media (max-width: 767px) {
                            width: 50%;
                        }
                        @media (max-width: 480px) {
                            width: 100%;
                        }
                        &:hover,
                        &:focus,
                        &:active {
                            background: transparent;
                        }
                        a {
                            &.amsearch-image {
                                .amsearch-product-image-container {
                                    .product-image-wrapper {
                                        height: auto !important;
                                        padding-bottom: 0 !important;
                                        img {
                                            position: initial;
                                            object-fit: contain;
                                            max-height: 195px;
                                            aspect-ratio: 1 / 1;
                                        }
                                    }
                                }
                            }
                        }
                        .amsearch-description {
                            width: 100%;
                            a {
                                color: $dark-grey !important;
                            }
                            .amsearch-wrapper-inner {
                                .price-box {
                                    display: block;
                                    padding-bottom: 3rem;
                                    .from_price {
                                        font-weight: 400;
                                        font-size: 16px;
                                    }
                                    .price {
                                        font-size: 17px;
                                    }
                                }
                            }
                        }
                        .product-item-actions {
                            display: flex !important;
                            align-items: center;
                            width: 100%;

                            .actions-primary {
                                .action.primary {
                                    font-weight: 700;
                                    font-size: 15px;
                                    @media (max-width: 767px) {
                                        font-size: 14px;
                                    }
                                }
                            }
                            .actions-secondary {
                                width: auto;
                                display: flex;
                                align-items: center;
                                .amsearch-button.-wishlist {
                                    background: url('../images/icons/icon-heart.svg') no-repeat transparent;
                                    background-size: contain;
                                    width: 16px;
                                    height: 16px;
                                }
                                .amsearch-button.-compare {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            // Recently Viewed
            .amsearch-products-list {
                order: -1;

                .amsearch-slider-section {
                    flex-wrap: wrap;
    
                    .amsearch-item {
                        max-width: none;
                        width: 25%;
                        @media (max-width: 991px) {
                            width: 25%;
                        }
                        @media (max-width: 767px) {
                            width: 50%;
                        }
                        @media (max-width: 480px) {
                            width: 100%;
                        }
                        &:hover,
                        &:focus,
                        &:active {
                            background: transparent;
                        }
                        a {
                            &.amsearch-image {
                                .product-image-container {
                                    .product-image-wrapper {
                                        height: auto !important;
                                        padding-bottom: 0 !important;
                                        img {
                                            position: initial;
                                            object-fit: contain;
                                            max-height: 195px;
                                            aspect-ratio: 1 / 1;
                                        }
                                    }
                                }
                            }
                        }
                        .amsearch-description {
                            width: 100%;
                            a {
                                color: $dark-grey !important;
                            }
                            .amsearch-wrapper-inner {
                                [data-role='priceBox'] {
                                    display: block;
                                    padding-bottom: 3rem;
                                    .from_price {
                                        font-weight: 400;
                                        font-size: 16px;
                                    }
                                    .price {
                                        font-size: 17px;
                                    }
                                }
                            }
                        }
                        .product-item-actions {
                            display: flex !important;
                            align-items: center;
                            width: 100%;
    
                            .actions-primary {
                                .action.primary {
                                    font-weight: 700;
                                    font-size: 15px;
                                    @media (max-width: 767px) {
                                        font-size: 14px;
                                    }
                                }
                            }
                            .actions-secondary {
                                width: auto;
                                display: flex;
                                align-items: center;
                                .amsearch-button.-wishlist {
                                    background: url('../images/icons/icon-heart.svg') no-repeat transparent;
                                    background-size: contain;
                                    width: 16px;
                                    height: 16px;
                                }
                                .amsearch-button.-compare {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }



}