#html-body {

    &.catalog-category-view {

        #breadcrumbs {
            background: #F7F7F7;
            max-width: none;
            ul {
                max-width: calc(1280px);
                margin: 0 auto;
            }
        }

        #maincontent {
            padding-top: 0;
        }

        .category-view {
            width: 100vw;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            background: #F7F7F7;
            padding-bottom: 56px;
            margin-bottom: 30px;
            @media (max-width: 767px) {
                padding-bottom: 46px;
            }
            &:has(.category-image) {
                .category-view-wrapper {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    @media (max-width: 767px) {
                        display: block;
                    }
                }
                .page-title-wrapper {
                    grid-row: 1;
                    grid-column: 1;
                    align-self: end;
                    .page-title {
                        display: flex;
                        padding-top: 0 !important;
                        margin-bottom: 0.5em !important;
                        @media (max-width: 767px) {
                            padding-top: 0.6em !important;
                            margin-bottom: 0.8em !important;
                        }
                    }
                }
                .category-description {
                    grid-row: 2;
                    grid-column: 1;
                    align-self: start;
                }
                .category-image {          
                    width: 100%;
                    grid-row: 1;
                    grid-column: 2;
                    grid-area: span 2 / span 1;
                    margin-bottom: 0;
                    @media (max-width: 767px) {
                        display: none;
                    }
                    img {
                        @media (max-width: 1200px) {
                            object-fit: cover;
                            min-height: 235px;
                        }
                    }
                }
            }

            .category-view-wrapper {
                display: block;
                column-gap: 56px;
                align-items: center;
                margin: auto;
                max-width: calc(1280px + 54px);

                $offset: 7px;
                padding-left: calc(#{$side-padding-t} + #{$offset});
                padding-right: calc(#{$side-padding-t} + #{$offset});
                @media (max-width: 767px) {
                    padding-left: calc(#{$side-padding-t} + #{$offset});
                    padding-right: calc(#{$side-padding-t} + #{$offset});
                }
            }
            .page-title-wrapper {
                width: 100%;
                .page-title {
                    display: flex;
                    padding-top: 0.5em;
                    margin-bottom: 0.6em;
                    @media (max-width: 767px) {
                        font-size: 32px;
                        padding-top: 0.6em !important;
                        margin-bottom: 0.8em !important;
                    }
                }
            }
            .category-description {
                width: 100%;
                margin-bottom: 0;
                div {
                    margin: 0 !important;
                    padding: 0 !important;
                }
            }
        }

        .page.messages {
            max-width: calc(1280px + 50px);
            margin: 0 auto;
            padding-top: 33px;
            padding-left: calc(#{$side-padding-m} + 7px);
            padding-right: calc(#{$side-padding-m} + 7px);
        }


        a.view-add-button {
            @include btn();
            @include btn-primary();
            font-size: 15px;
            padding: 13px 17px;
        }

        .block.block-reorder {
            .actions-toolbar {
                flex-direction: column;
                justify-content: center;
                .primary,
                .secondary {
                    margin-right: 0;
                }
            }
        }


    }
}

.page-products {
    &.category-testpots .filter-current ~ .filter-options, 
    &.category-testpots .filter-current ~ .filter-subtitle, 
    .catalog-topnav {
        display: none !important;
    }
    .product-item-photo {
        display: flex;
        align-items: center;
        justify-content: center;
        aspect-ratio: 1 / 1;
        img {
            height: auto;
            margin: auto;
            max-height: 100%;
            margin-top: 3rem;
            margin-bottom: 1rem;
            object-fit: contain;
            max-height: 240px;
            aspect-ratio: 1 / 1;
        }
    }
}

.options {
    width: 35px;
    height: 35px;
    margin-right: 20px;
    margin-top: 5px;
    margin-left: 5px;
    display: inline-block;

    .text {
        font-size: 14px;
        margin-top: 15px;
        display: inline-block;
    }
}


// Mini cart
#minicart-content-wrapper {
    .items-total {
        display: none;
    }
}

// Checkout
.modal-inner-wrap {
    .title {
        border-bottom: none !important;
        strong[role=heading] {
            display: none;
        }
    }
}

// paint calculator
#paint-calculator-container {
    .fl-right {
        float: right;
    }
    #paint_calculator_modal {
        text-align: left;
    }
    .calculator-model-body {
        margin-top: 3rem;
    }
    .calculator-model-body label {
        font-weight: bold;
    }
    .calculator-top {
        margin-bottom: 2rem;
    }
    #calculator-inputs input[disabled] {
        background: #eee;
    }
    #calculator-outputs .suffix {
        font-size: 2rem;
    }
    #calculate_output {
        width: 100%;
    }
}

.filter-options {
    .select2-container {
        width: 100% !important;
    }
}

.input-box {
    .select2-container {
        width: 100% !important;
    }
}

// Hide Base ID field
label[for='attribute166'] {
    display: none !important;
}

label[for='attribute166'] + div {
    display: none !important;
}

// Start Of Reconfiguration styles
//////////////////////////////////////////////////////
@media (min-width: 1045px) {

    // main product image
    .page-layout-1column {
        .product.media {
            width: 33% !important;
            float: left;
            margin-bottom: 25px;
        }
    }

    // configurable options
    .page-layout-1column {
        .product-info-main {
            width: 25% !important;
            margin-top: 40px;
            border-left: 1px solid lightgrey;
            padding-left: 40px;
            margin-bottom: 30px;
        }
    }

    .colour_sample {
        background-color: white !important;
    }

    .attribute {
        .value {
            margin-bottom: 10px !important;
        }
    }

    // related block
    .related-product-view {
        font-size: 1.4rem;
    }

    .related {
        clear: both;
        border-top: 1px solid lightgrey;
        padding-top: 20px;
    }


}


////////////////////////////////////////////////

// Product page configurable filter
.product-info-main {

    .product-add-form {
        margin-top: 15px;
    }
    .product-reviews-summary {
        display: none;
    }
    .prices-tier {
        display: none;
    }

    .overview {
        border-bottom: 1px solid #ccc;
        font-size: 18px;
        padding-bottom: 12px;
    }
}

#select2-colourNameDropdown-results {
    .select2-results__option {
        .options {
            height: 24px;
            margin-right: 12px;
            width: 24px;
        }
        .text {
            margin-top: 0;
        }

        @media (min-width: 768px) and (max-width: 991px) {
            .options {
                height: 16px;
                margin-top: 0;
                width: 80px;

            }
        }
    }
}

.toolbar-products {
    margin-bottom: 0;
}
.product-item-link {
    font-size: 16px;
}

.product-item {
    height: auto;

    .product-item-info {
        width: auto;
    }

    .price-box {
        margin: 10px 0 8px;
        min-height: 3.1em;
        .from_price,
        .price-label {
            font-size: 16px;
            line-height: 1;
        }

        .price {
            line-height: 1;
            font-size: 18px;
        }
        .special-price {
            line-height: 1;
        }
        .old-price {
            line-height: 1;
            text-decoration: none;
            position: relative;
            display: inline-block;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                height: 1px;
                background-color: currentColor;
                transform: translateY(-50%);
                margin-top: 0px;
            }
            .price {
                font-weight: 700;
            }
        }
    }
    .product-item-name {
        min-height: 3.3em;
        @media (max-width: 1140px)  {
            min-height: 5em;
        }
        a {
            line-height: 1.1;
            color: $black !important;
        }
    }

    .product-item-actions {
        display: block !important;
        line-height: 3;
        .actions-primary {
            width: 100%;
            display: table-cell;
            .action.primary {
                width: 100%;
                text-align: center;
                @media (max-width: 767px)  {
                    font-size: 13px;
                    padding: 15px 0;
                }
            }
        }
        .actions-secondary {
            display: table-cell;
            padding-left: 5px;
            white-space: nowrap;
            width: 50%;
        }
    }
}

//Product page information section
@media (min-width: 768px) {
    .product {
        .switch {
            height: 31px !important;
        }
    }

    .product.data.items > .item.content {
        padding: 20px 20px 20px 0 !important;
    }
}

@media (max-width: 767px) {
    .product {
        .switch {
            line-height: 30px !important;
        }
    }
}

.fotorama__stage {
    max-height: 500px !important;
}

.product-add-form {
    h4 {
        text-transform: uppercase;
    }
    .colour-select {
        &:after {
            content: '*';
            color: #e02b27;
            font-size: 1.2rem;
            margin: 0 0 0 5px;
        }
    }
}

.product-item-actions {

    .product-item-name {
        float: left;
        width: 90px;
    }

    .secondary-addto-links {
        float: right;
        width: 20px;
        margin-top: 5px;
    }

    .addtocart {
        float: left;
        width: 100px;
        margin-top: 5px;
    }
}

// Related product section
.related-product-view {
    font-size: 1.4rem;
}

.accessories-right {
    text-align: left;

    .accessories-select {
        margin-bottom: 10px;
        margin-top: 2px;
    }

    .label {
        font-weight: 700;
        text-transform: capitalize;
    }

    .qty-accessories {
        margin-bottom: 20px;
        width: 45px;
        display: inherit;
    }

    .accessories-button {
        float: right;
        margin-top: -30px;
    }

}

// TODO: This is overriding the styles-m.css file which hides the table cells on mobile.
@media only screen and (max-width: 639px) {
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison).additional-attributes tbody th {
        display: block !important;
    }
}

// .product-item-info .price-box {
//     min-height: 34px;
//     .price {
//         font-size: 20px;
//     }
// }

.product-item-inner .box-tocart .fieldset .primary .action.primary {
    width: 100%;
    text-align: center;  
}

.product-item-inner .box-tocart .fieldset .product-item-actions .actions-primary {
    display: block;
    button {
        height: 40px;
    }
}
.products-grid.wishlist .product-item-tooltip {
    display: none;
}
.products-grid.wishlist .product-items .product-item .product-item-info .product-item-name {
    min-height: 46px;
}
